import { Select } from "antd";
import { useTranslation } from "react-i18next";

const SelectRepresentant = ({ onRepChange, usRepresentants }) => {
  const { t } = useTranslation();

  const pushRepInOptions = () => {
    const options = [];
    if (usRepresentants.length) {
      usRepresentants?.forEach((rep) => {
        options.push({ label: `${rep.Nom} ${rep.Prenom}`, value: rep.Id });
      });
    }
    return options;
  };
  return (
    <>
      <h3>{t("Représentant")}</h3>
      <Select
        onChange={onRepChange}
        options={pushRepInOptions()}
        style={{ width: "100%" }}
      ></Select>
    </>
  );
};

export default SelectRepresentant;
