import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";

const AuthGuard = () => {
  const { user } = useContext(AuthContext);

  if (!user?.role || user?.role === "guest") {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default AuthGuard;
