import { useContext, useEffect } from "react";
import CartContext from "../../contexts/CartContext";
import { GoToCart } from "../boutons";
import { Card } from "antd";
import "./cart.css";
import { useTranslation } from "react-i18next";

const Cart = ({ marginLeftContent }) => {
	const { cart, getCart, count, getCartCount } = useContext(CartContext);
	const { t } = useTranslation();

	useEffect(() => {
		getCartCount();
	}, [getCart]);

	return cart.length ? (
		<Card className="cart-container" style={{ marginLeft: marginLeftContent }}>
			<p style={{ margin: 0 }}>
				{t("Quantité totale")}: {count}
			</p>
			<GoToCart />
		</Card>
	) : (
		""
	);
};

export default Cart;
