import { useContext, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { Button, Card, Col } from "antd";
import { Loader } from "../loader";
import { PauseCircleTwoTone } from "@ant-design/icons";
import "./dashboard-agent.css";
import ModalAgentReliquat from "./ModalAgentReliquat";
import { useTranslation } from "react-i18next";

const TotalReliquatByAgent = () => {
  const [isModalReliquatOpen, setIsModalReliquatOpen] = useState(false);
  const { totalReliquatByRepresentant, viewportWidth } = useContext(MainContext);

  const { t } = useTranslation();

  const showModalReliquat = () => {
    setIsModalReliquatOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalReliquatOpen(false);
  };

  return (
    <>
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Card
          title={
            viewportWidth < 1181 ? (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0 }}>{t("RELIQUAT")}</p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <PauseCircleTwoTone twoToneColor="#eccc68" />
                </span>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0 }}> {t("RELIQUAT TOTAL")}</p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <PauseCircleTwoTone twoToneColor="#eccc68" />
                </span>
              </div>
            )
          }
          style={{ height: "100%" }}
          bodyStyle={{
            padding: "16px 4px",
            textAlign: "center",
            fontSize: "2rem",
          }}>
          {totalReliquatByRepresentant?.QuantiteTotale &&
          totalReliquatByRepresentant?.QuantiteTotale > 0 ? (
            <>
              <p style={{ margin: 0 }}>
                {totalReliquatByRepresentant?.QuantiteTotale}{" "}
                <span style={{ fontSize: "1rem" }}>pièces</span>
              </p>
              <Button type="ghost" onClick={showModalReliquat}>
                {t("Plus d'infos")}
              </Button>
            </>
          ) : totalReliquatByRepresentant?.QuantiteTotale == 0 ? (
            <>
              <p style={{ margin: 0 }}>
                0 <span style={{ fontSize: "1rem" }}>pièces</span>
              </p>
            </>
          ) : (
            <Loader />
          )}
          <ModalAgentReliquat
            open={isModalReliquatOpen}
            onOk={handleCloseModal}
            reliquat={totalReliquatByRepresentant?.lignesArticles}
            viewportWidth={viewportWidth}
          />
        </Card>
      </Col>
    </>
  );
};

export default TotalReliquatByAgent;
