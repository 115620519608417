import { Button, Col, Divider, Modal, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";

const ModalHistoriqueBonCommande = ({ open, onOk, detailsBon }) => {
  const [articlesByBon, setArticlesByBon] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getArticlesByDoc, viewportWidth } = useContext(MainContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchLignesByCommande(detailsBon) {
      setIsLoading(true);
      const articlesByBon = await getArticlesByDoc(1, detailsBon?.NumeroDocument);
      setArticlesByBon(articlesByBon);
      setIsLoading(false);
    }
    fetchLignesByCommande(detailsBon);
  }, [detailsBon]);

  if (articlesByBon === "offline") {
    return (
      <Modal
        title={t("BON DE COMMANDE")}
        open={open}
        onOk={onOk}
        closable={true}
        onCancel={onOk}
        footer={[
          <Button key="back" onClick={onOk}>
            {t("Fermer")}
          </Button>,
        ]}
        width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}>
        <Row>
          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
            {articlesByBon?.length > 1 ? "Articles" : "Article"}
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
            {t("Prix")}
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
            {t("Qté")}
          </Col>
        </Row>
        <Divider style={{ marginTop: "12px" }} />
        <h3>Pas de connexion internet, le détails de ce document ne peut pas être affiché</h3>
      </Modal>
    );
  }

  return (
    <Modal
      title={t("BON DE COMMANDE")}
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}>
      <Row>
        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
          {articlesByBon?.length > 1 ? "Articles" : "Article"}
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
          {t("Prix")}
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
          {t("Qté")}
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />

      {isLoading ? (
        <Loader />
      ) : articlesByBon?.length ? (
        articlesByBon?.map((ligne) => (
          <Row key={ligne.Id}>
            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
              {ligne?.Designation}
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
              {ligne?.PrixUnitaire}€
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
              {ligne?.Quantite}
            </Col>
            <Divider />
          </Row>
        ))
      ) : (
        "N/A"
      )}
    </Modal>
  );
};

export default ModalHistoriqueBonCommande;
