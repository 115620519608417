import { Card, Col, Segmented, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalLastsOrders } from "../modal-client-lasts-orders";
import { Loader } from "../loader";

export const DashboardClientHistoriqueCommandes = ({ lastOrders, isLoadingLastOrders }) => {
  const [dataSource, setDataSource] = useState([]);
  const [docType, setDocType] = useState("bc");
  const [bc, setBc] = useState([]);
  const [bl, setBl] = useState([]);
  const [fc, setFc] = useState([]);
  const [isModalBCOpen, setIsModalBCOpen] = useState(false);
  const [isModalBLOpen, setIsModalBLOpen] = useState(false);
  const [isModalFCOpen, setIsModalFCOpen] = useState(false);
  const [clickedBon, setClickedBon] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setBc(lastOrders?.BC.reverse() || []);
    setBl(lastOrders?.BL.reverse() || []);
    setFc(lastOrders?.FC.reverse() || []);
  }, [lastOrders]);

  useEffect(() => {
    setDataSource(bc);
  }, [bc]);

  const handleOpenModalBC = () => {
    setIsModalBCOpen(true);
  };

  const handleOpenModalBL = () => {
    setIsModalBLOpen(true);
  };

  const handleOpenModalFC = () => {
    setIsModalFCOpen(true);
  };

  const handleCloseModalBC = () => {
    setIsModalBCOpen(false);
  };

  const handleCloseModalBL = () => {
    setIsModalBLOpen(false);
  };

  const handleCloseModalFC = () => {
    setIsModalFCOpen(false);
  };

  const onChangeCategory = (value) => {
    if (value === t("Bons de commande")) {
      setDataSource(bc);
      setDocType("bc");
    }

    if (value === t("Bons de livraison")) {
      setDataSource(bl);
      setDocType("bl");
    }

    if (value === t("Factures comptabilisées")) {
      setDataSource(fc);
      setDocType("fc");
    }
  };

  const handleOpenModalFunctions = {
    bc: handleOpenModalBC,
    bl: handleOpenModalBL,
    fc: handleOpenModalFC,
  };

  const isModalOpen = {
    bc: isModalBCOpen,
    bl: isModalBLOpen,
    fc: isModalFCOpen,
  };

  const handleCloseModal = {
    bc: handleCloseModalBC,
    bl: handleCloseModalBL,
    fc: handleCloseModalFC,
  };

  const columns = [
    {
      title: "N° Doc",
      dataIndex: "numeroDocument",
      render: (numeroDocument) => numeroDocument,
    },
    {
      title: t("Montant Total"),
      dataIndex: "products",
      key: "totalMontantHT",
      render: (products) => {
        const totalMontant = products.reduce((sum, products) => sum + products.MontantHT, 0);
        return `${totalMontant.toFixed(2)}€`;
      },
      responsive: ["md"],
    },
    {
      title: "Date",
      dataIndex: "products",
      key: "Date",
      render: (products) =>
        new Date(parseInt(products[0]?.Date?.split("(")[1].split(")")[0])).toLocaleDateString(
          "fr-FR"
        ),
    },
  ];

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card style={{ height: "100%" }} title={t("Historique").toUpperCase()}>
          <Segmented
            block
            options={[t("Bons de commande"), t("Bons de livraison"), t("Factures comptabilisées")]}
            onChange={onChangeCategory}
            style={{ marginBottom: "8px" }}
          />
          <Table
            rowKey={(record, index) => {
              return `${record.Intitule}${index}`;
            }}
            onRow={(doc) => {
              return {
                onClick: () => {
                  setClickedBon(doc);
                  const functionToCall = handleOpenModalFunctions[docType];
                  if (functionToCall) functionToCall();
                  else
                    console.error(`Aucune fonction trouvée pour le type de document : ${docType}`);
                },
              };
            }}
            pagination={false}
            columns={columns}
            dataSource={dataSource ?? []}
            loading={{ spinning: isLoadingLastOrders, indicator: <Loader /> }}
            size="small"
            bordered
            scroll={{
              y: 124,
            }}
          />
        </Card>
      </Col>
      <ModalLastsOrders
        open={isModalOpen[docType]}
        onOk={handleCloseModal[docType]}
        detailsBon={clickedBon.products}
        docType={docType}
      />
    </>
  );
};
