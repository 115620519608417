import {
  CAByYear,
  NewClients,
  ObjectifCA,
  TopAndFlopClients,
  TotalReliquatByAgent,
  FacturesImpayees,
} from "../../components";
import { Row } from "antd";

const ActivityByAgentPage = () => {
  return (
    <Row gutter={[16, 16]}>
      <CAByYear />
      <ObjectifCA />
      <TotalReliquatByAgent />
      <FacturesImpayees />
      <NewClients />
      <TopAndFlopClients />
    </Row>
  );
};

export default ActivityByAgentPage;
