import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import AuthContext from "../../contexts/AuthContext";
import PriceContext from "../../contexts/PriceContext";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const UpdateClientBtn = {
  backgroundColor: "#6ab04c",
  width: "100%",
  height: "100%",
  padding: "12px",
  border: "none",
  color: "white",
  fontSize: "1.4rem",
  fontWeight: "bold",
  cursor: "pointer",
};

const ResetClientBtn = {
  backgroundColor: "#c0392b",
  width: "100%",
  height: "100%",
  padding: "12px",
  border: "none",
  color: "white",
  fontSize: "1.4rem",
  fontWeight: "bold",
  cursor: "pointer",
};

const UpdateClientPage = () => {
  const [clientInfos, setClientInfos] = useState({});
  const [pays, setPays] = useState("France");
  const [billingTerms, setBillingTerms] = useState(null);

  const { getClientById, clientById, setCurrent } = useContext(MainContext);
  const { accessTokenJwt, isLoadingAccessToken } = useContext(AuthContext);
  const { activeRole } = useContext(PriceContext);
  const { idClient } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    const getBillingTerms = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/billingTerms`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      const data = await response.json();
      setBillingTerms(data);
    };
    getBillingTerms();
  }, [idClient]);

  useEffect(() => {
    getClientById(idClient);
  }, [idClient]);

  const validateMessages = {
    required: "${label} est requis !",
    types: {
      email: "${label} n'est pas un email valide !",
      number: "${label} n'est pas un chiffre valide !",
    },
  };

  const onFinish = async () => {
    if (!isLoadingAccessToken) {
      const translatedCountry = t(pays, { lng: "fr" });
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/update`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessTokenJwt}`,
          },
          body: JSON.stringify({
            tiers: {
              __type: "Client:http://www.proconsult.lu/WebServices100",
              TypeTiers: 0,
              NumeroTiers: clientById.NumeroTiers,
              NumeroTiersPayeur: clientById.NumeroTiers,
              IdCategorieComptable: 1,
              IdCategorieTarifaire: Math.floor(clientInfos.pays),
              IdConditionLivraison: 1,
              IdModeExpedition: 1,
              IdModeleReglement: 18,
              Intitule: clientInfos.intitule,
              Qualite: "",
              Abrege: clientInfos.intitule,
              Contact: clientInfos.contact,
              Adresse: clientInfos.adresse,
              Complement: clientInfos.complement ?? "",
              CodePostal: clientInfos.codePostal,
              Ville: clientInfos.ville,
              Region: "",
              Pays: translatedCountry,
              Telephone: clientInfos.telephone,
              Email: clientInfos.email,
              SiteWeb: clientInfos.siteWeb ?? "",
              Statistique03: clientInfos.billingTerms ?? "",
              Commentaire: clientInfos.commentaire ?? "",
              TauxReleve: 0,
              TauxRFA: 0,
              Facture: 1,
              Saut: true,
              Analytique: 0,
              CodeAffaire: null,
              IdRepresentant: clientInfos.repId,
              NumeroSiret: clientInfos.numeroSIRET ?? "",
              NumeroTva: clientInfos.numeroTVA ?? "",
            },
          }),
        });
        if (response.status !== 200) {
          toast.error("Une erreur est survenue ! \nCode erreur: " + response.status);
        } else {
          // console.log("Validé !", clientInfos);
          toast.success("Client modifié avec succès !");
          setTimeout(() => {
            navigate(`/clients/${clientById.NumeroTiers}`);
            setCurrent("/clients");
          }, 2000);
        }
      } catch (error) {
        console.log("erreur", error);
      }
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
    toast.error("Tous les champs sont requis !");
  };

  const onReset = () => {
    form.resetFields();
  };

  const onValuesChange = async (changedValues, allValues) => {
    setClientInfos({
      ...allValues.client,
      repId: clientById?.IdRepresentant,
      numeroTiers: clientById?.NumeroTiers,
    });
  };

  const savePaysLabel = (value, option) => {
    setPays(option?.label);
  };

  return (
    <>
      <div className="create-client__form-container">
        <Form
          validateMessages={validateMessages}
          form={form}
          layout="vertical"
          style={{ width: "100%" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          initialValues={{
            remember: true,
            client: {
              intitule: clientById?.Intitule,
              adresse: clientById?.Adresse,
              complement: clientById?.Complement,
              codePostal: clientById?.CodePostal,
              ville: clientById?.Ville,
              contact: clientById?.Contact,
              telephone: clientById?.Telephone,
              email: clientById?.Email,
              siteWeb: clientById?.SiteWeb,
              numeroSIRET: clientById?.NumeroSiret,
              numeroTVA: clientById?.NumeroTva,
              billingTerms: clientById?.Statistique03 ?? "",
              commentaire: clientById?.Commentaire,
            },
          }}>
          <Form.Item
            name={["client", "intitule"]}
            label={t("Intitulé du magasin")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>

          <Form.Item
            name={["client", "adresse"]}
            label={t("Adresse")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "complement"]}
            label={t("Complément d'adresse")}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "codePostal"]}
            label={t("Code postal")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input type="number" min="0" max="99999" maxLength={5} />
          </Form.Item>

          <Form.Item
            name={["client", "ville"]}
            label={t("Ville")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "pays"]}
            label={t("Pays")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Select
              allowClear
              showSearch
              placeholder={t("Pays")}
              onChange={savePaysLabel}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
              }
              options={[
                { value: 1, label: t("France") },
                { value: 2, label: t("Danemark") },
                { value: 2.1, label: t("Norvège") },
                { value: 2.2, label: t("Suède") },
                { value: 3, label: t("Canada") },
                { value: 4, label: t("USA") },
                { value: 5, label: t("Allemagne") },
                { value: 5.1, label: t("Pays-Bas") },
                { value: 6, label: t("Suisse") },
                { value: 7, label: t("Royaume-Uni") },
                { value: 8, label: t("Espagne") },
                { value: 9, label: t("Italie") },
                { value: 10, label: t("Australie") },
                { value: 13, label: t("Portugal") },
                { value: 14, label: t("Maroc") },
                { value: 15, label: t("Corée") },
                { value: 1.1, label: t("Algérie") },
                { value: 1.2, label: t("Arabie Saoudite") },
                { value: 5.2, label: t("Autriche") },
                { value: 1.3, label: t("Belgique") },
                { value: 1.4, label: t("Bénin") },
                { value: 1.5, label: t("Brésil") },
                { value: 12, label: t("Chine") },
                { value: 1.6, label: t("Cote D'Ivoire") },
                { value: 1.7, label: t("Emirats Arabes Unis") },
                { value: 2.3, label: t("Estonie") },
                { value: 2.4, label: t("Finlande") },
                { value: 1.8, label: t("Gabon") },
                { value: 12.1, label: t("Grèce") },
                { value: 11, label: t("Guadeloupe") },
                { value: 1.9, label: t("Guatemala") },
                { value: 1.23, label: t("Guernesay") },
                { value: 12.2, label: t("Hong Kong") },
                { value: 11.1, label: t("Ile Maurice") },
                { value: 11.2, label: t("Iles Canaries") },
                { value: 7.1, label: t("Irlande") },
                { value: 1.11, label: t("Israël") },
                { value: 1.12, label: t("Japon") },
                { value: 1.13, label: t("Kenya") },
                { value: 2.5, label: t("Lettonie") },
                { value: 1.14, label: t("Liban") },
                { value: 1.15, label: t("Liechtenstein") },
                { value: 1.16, label: t("Luxembourg") },
                { value: 11.3, label: t("Martinique") },
                { value: 11.4, label: t("Nouvelle-Calédonie") },
                { value: 1.17, label: t("Paraguay") },
                { value: 2.6, label: t("Pologne") },
                { value: 11.5, label: t("Polynésie française") },
                { value: 1.18, label: t("République Dominicaine") },
                { value: 11.6, label: t("Réunion") },
                { value: 2.7, label: t("Roumanie") },
                { value: 12.3, label: t("Russie") },
                { value: 1.19, label: t("Sénégal") },
                { value: 2.8, label: t("Slovaquie") },
                { value: 1.22, label: t("Togo") },
                { value: 1.21, label: t("Turquie") },
              ]}></Select>
          </Form.Item>

          <Form.Item
            name={["client", "contact"]}
            label="Contact"
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "telephone"]}
            label={t("Téléphone")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "email"]}
            label="Email"
            rules={[{ required: true, type: "email" }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "siteWeb"]}
            label={t("Site Web")}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          {/* Non obligatoire coté Sage */}
          {pays === "France" ? (
            <Form.Item
              name={["client", "numeroSIRET"]}
              label={t("Numéro de SIRET")}
              rules={[{ required: true }]}
              style={{ marginBottom: "8px" }}>
              <Input />
            </Form.Item>
          ) : (
            <Form.Item
              name={["client", "numeroTVA"]}
              label={t("Numéro de TVA")}
              rules={[{ required: true }]}
              style={{ marginBottom: "8px" }}>
              <Input />
            </Form.Item>
          )}
          {activeRole === "repUs" && pays === "Etats-Unis" && billingTerms?.length > 0 ? (
            <Form.Item
              name={["client", "billingTerms"]}
              label={t("Billing Terms")}
              rules={[{ required: false }]}
              style={{ marginBottom: "8px" }}>
              <Select
                allowClear
                showSearch
                placeholder={t("Billing Terms")}
                // onChange={savePaysLabel}
                options={billingTerms?.map((bt) => ({
                  value: bt.Intitule,
                  label: bt.Intitule,
                }))}></Select>
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item
            name={["client", "commentaire"]}
            label={t("Commentaire")}
            style={{ marginBottom: "8px" }}>
            <TextArea rows={4} maxLength={35} placeholder={t("35 caractères maximum")} />
          </Form.Item>
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={16} lg={14} xl={18}>
              <Button htmlType="submit" style={UpdateClientBtn}>
                {t("Modifier")} <CheckOutlined />
              </Button>
            </Col>
            <Col xs={24} sm={24} md={8} lg={10} xl={6}>
              <Button htmlType="button" style={ResetClientBtn} onClick={onReset}>
                {t("Réinitialiser")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default UpdateClientPage;
