import { Button, Col, Divider, Modal, Row } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const ModalLastsOrders = ({ open, onOk, detailsBon, docType }) => {
  const { t } = useTranslation();
  const { viewportWidth } = useContext(MainContext);

  const title = {
    bc: t("BON DE COMMANDE"),
    bl: t("BON DE LIVRAISON"),
    fc: t("FACTURE COMPTABILISEE"),
  };

  return (
    <Modal
      title={title[docType]}
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}>
      <Row>
        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
          {detailsBon?.length > 1 ? "Articles" : "Article"}
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
          {t("Prix")}
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
          {t("Qté")}
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      {detailsBon?.length
        ? detailsBon?.map((ligne) => (
            <Row key={ligne.Id}>
              <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                {ligne?.Designation}
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
                {ligne?.MontantHT}€
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
                {ligne?.Quantite}
              </Col>
              <Divider />
            </Row>
          ))
        : "N/A"}
    </Modal>
  );
};

export default ModalLastsOrders;
