import { useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { Card, Col, Table, Segmented } from "antd";
import { useTranslation } from "react-i18next";

const TopAndFlopClients = () => {
  const { topAndFlopClientsByRepresentant, viewportWidth } =
    useContext(MainContext);

  const { t } = useTranslation();

  useEffect(() => {
    // console.log(topAndFlopClientsByRepresentant);
    setDataSource(topAndFlopClientsByRepresentant?.top10Clients);
  }, [topAndFlopClientsByRepresentant]);

  /** Ajout */
  const [dataSource, setDataSource] = useState(
    topAndFlopClientsByRepresentant?.top10Clients
  );

  const onChangeCategory = (value) => {
    if (value === "Top 10") {
      setDataSource(topAndFlopClientsByRepresentant?.top10Clients);
    }

    if (value === t("Actifs")) {
      setDataSource(topAndFlopClientsByRepresentant?.topClients);
    }

    if (value === t("Inactifs")) {
      setDataSource(topAndFlopClientsByRepresentant?.flopClients);
    }
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "Intitule",
      key: "Intitule",
    },
    {
      title: t("Chiffre d'affaire"),
      dataIndex: "NetAPayerCumule",
      key: "Intitule",
      render: (ca) => `${ca?.toFixed(2)}€`,
      width: "130px",
    },
  ];

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Segmented
            block
            options={["Top 10", t("Actifs"), t("Inactifs")]}
            onChange={onChangeCategory}
            style={{ marginBottom: "8px" }}
          />
          <Table
            rowKey={(record, index) => {
              return `${record.Intitule}${index}`;
            }}
            pagination={false}
            columns={columns}
            dataSource={dataSource ?? []}
            size="small"
            bordered
            scroll={{
              y: viewportWidth < 400 ? 200 : viewportWidth < 1081 ? 150 : 200,
            }}
          />
        </Card>
      </Col>
    </>
  );
};

export default TopAndFlopClients;
