import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider as MainProvider } from "./contexts/MainContext";
import { Provider as CartProvider } from "./contexts/CartContext";
import { Provider as AuthProvider } from "./contexts/AuthContext";
import { Provider as PriceProvider } from "./contexts/PriceContext";
import { Provider as SearchProvider } from "./contexts/SearchContext";
import {
  ErrorPage,
  ProduitsPage,
  ProduitsPageByFamille,
  ClientsPage,
  CreateClientPage,
  UpdateClientPage,
  DefaultPage,
  DetailsProduit,
  LoginPage,
  CartPage,
  DetailsClient,
  HistoriqueBonCommande,
  HistoriquePrepaLivraison,
  HistoriqueBonsDeLivraison,
  HistoriqueFacturesSimples,
  HistoriqueFacturesComptabilisees,
  ActivityByAgentPage,
  DownloadsPage,
  WaitlistOrders,
} from "./containers/";
import { AuthGuard } from "./components/";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <DefaultPage /> },
      {
        path: "/produits",
        element: <ProduitsPage />,
      },
      {
        path: "/produits/:idProduit",
        element: <DetailsProduit />,
      },
      {
        path: "/produits/famille/:marque",
        element: <ProduitsPageByFamille />,
      },
      {
        path: "/clients",
        element: <AuthGuard />,
        children: [{ index: true, element: <ClientsPage /> }],
      },
      {
        path: "/clients/:idClient",
        element: <AuthGuard />,
        children: [{ index: true, element: <DetailsClient /> }],
      },
      {
        path: "/client/create",
        element: <AuthGuard />,
        children: [{ index: true, element: <CreateClientPage /> }],
      },
      {
        path: "/client/update/:idClient",
        element: <AuthGuard />,
        children: [{ index: true, element: <UpdateClientPage /> }],
      },
      {
        path: "/historique/bons-de-commande",
        element: <AuthGuard />,
        children: [{ index: true, element: <HistoriqueBonCommande /> }],
      },
      {
        path: "/historique/prepa-de-livraison",
        element: <AuthGuard />,
        children: [{ index: true, element: <HistoriquePrepaLivraison /> }],
      },
      {
        path: "/historique/bons-de-livraison",
        element: <AuthGuard />,
        children: [{ index: true, element: <HistoriqueBonsDeLivraison /> }],
      },
      {
        path: "/historique/factures",
        element: <AuthGuard />,
        children: [{ index: true, element: <HistoriqueFacturesSimples /> }],
      },
      {
        path: "/historique/factures-comptabilisees",
        element: <AuthGuard />,
        children: [
          { index: true, element: <HistoriqueFacturesComptabilisees /> },
        ],
      },
      {
        path: "/representant/:repId",
        element: <AuthGuard />,
        children: [{ index: true, element: <ActivityByAgentPage /> }],
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/cart",
        element: <AuthGuard />,
        children: [{ index: true, element: <CartPage /> }],
      },
      {
        path: "/downloads",
        element: <AuthGuard />,
        children: [{ index: true, element: <DownloadsPage /> }],
      },
      {
        path: "/waitlist-orders/:repId",
        element: <AuthGuard />,
        children: [{ index: true, element: <WaitlistOrders /> }],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <MainProvider>
      <PriceProvider>
        <CartProvider>
          <SearchProvider>
            <RouterProvider router={router} />
          </SearchProvider>
        </CartProvider>
      </PriceProvider>
    </MainProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
