import { Button, Form, Input } from "antd";
import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import "./login-page.css";

const LoginPage = () => {
  const { checkUserConnexion } = useContext(AuthContext);

  const onFinish = (values) => {
    // console.log("Success:", values);
    checkUserConnexion(values.email, values.mdp);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page-wrapper">
      <Form
        className="form-wrapper"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Merci de renseigner votre email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="mdp"
          rules={[
            {
              required: true,
              message: "Merci de renseigner votre mot de passe",
            },
          ]}
        >
          <Input.Password placeholder="Mot de passe" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Connexion
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
