import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Produit, Loader, SearchBar } from "../../components/";
import MainContext from "../../contexts/MainContext";
import { Col, Empty, Row } from "antd";
import AuthContext from "../../contexts/AuthContext";

const ProduitsPage = () => {
	const { isLoadingProducts, products } = useContext(MainContext);
	const { isLoadingAccessToken } = useContext(AuthContext);
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		if (!isLoadingAccessToken) {
			getFilteredProducts();
		}
	}, [products]);

	const getFilteredProducts = async (search) => {
		let filteredProductsTmp;

		if (search) {
			filteredProductsTmp = products.filter((produit) =>
				produit.Intitule.toLowerCase().includes(search.toLowerCase())
			);
		} else {
			filteredProductsTmp = products;
		}
		setFilteredProducts(filteredProductsTmp);
	};

	const handleOnSearch = (value) => {
		getFilteredProducts(value);
	};

	return isLoadingProducts ? (
		<Loader />
	) : filteredProducts.length ? (
		<div>
			<SearchBar onSearch={handleOnSearch} />
			<Row gutter={[16, 16]}>
				{filteredProducts.map((produit) => {
					return (
						<Col xs={24} sm={12} md={12} lg={8} key={produit.Reference}>
							<Link to={`${produit.Reference}`} key={produit.Reference}>
								<Produit produit={produit} />
							</Link>
						</Col>
					);
				})}
			</Row>
		</div>
	) : (
		<>
			<SearchBar onSearch={handleOnSearch} />
			<Empty
				description={<span>Aucun produit ne correspond à votre recherche</span>}
			/>
		</>
	);
};

export default ProduitsPage;
