import { Input } from "antd";
import { useTranslation } from "react-i18next";

const CommentsOnCart = ({ onCommentChange, waitingOrderComment }) => {
  const { TextArea } = Input;
  const { t } = useTranslation();

  const handleComChange = (e) => {
    onCommentChange(e.target.value);
  };

  return (
    <>
      <h3>{t("Commentaire")}</h3>
      <TextArea
        showCount
        maxLength={300}
        rows={4}
        onChange={handleComChange}
        value={waitingOrderComment}
      ></TextArea>
    </>
  );
};

export default CommentsOnCart;
