import { useContext } from "react";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { Card, Col } from "antd";
import { FireTwoTone } from "@ant-design/icons";
import "./dashboard-agent.css";
import { useTranslation } from "react-i18next";

const NewClients = () => {
  const { newClientsByRepresentant, viewportWidth } = useContext(MainContext);

  const { t } = useTranslation();

  return (
    <>
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Card
          title={
            viewportWidth < 1181 ? (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0 }}>{t("NVX CLIENTS")}</p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <FireTwoTone twoToneColor="#EA2027" />
                </span>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0 }}>{t("NOUVEAUX CLIENTS (ANNÉE EN COURS)")}</p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <FireTwoTone twoToneColor="#EA2027" />
                </span>
              </div>
            )
          }
          style={{ height: "100%" }}
          bodyStyle={{
            padding: "16px 4px",
            textAlign: "center",
            fontSize: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {newClientsByRepresentant && newClientsByRepresentant > 0 ? (
            <p style={{ margin: 0 }}>{parseInt(newClientsByRepresentant)}</p>
          ) : newClientsByRepresentant == 0 ? (
            <p style={{ margin: 0 }}>0</p>
          ) : (
            <Loader />
          )}
        </Card>
      </Col>
    </>
  );
};

export default NewClients;
