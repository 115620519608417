import { Space, Table, Button, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useContext, useRef, useEffect } from "react";
import Highlighter from "react-highlight-words";
import MainContext from "../../contexts/MainContext";
import AuthContext from "../../contexts/AuthContext";
import SearchContext from "../../contexts/SearchContext";
import { ModalHistoriqueFacture } from "../../components/modal-historique-facture";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const HistoriqueFacturesComptabilisees = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isModalFacturesOpen, setIsModalFacturesOpen] = useState(false);
  const [clickedBon, setClickedBon] = useState();
  const { viewportWidth, facturesComptabiliseesAgent, getDocumentsByRepresentant } =
    useContext(MainContext);
  const { user } = useContext(AuthContext);
  const { filters, saveFilters } = useContext(SearchContext);

  const tableId = "facturesComptabiliseesTable";
  const { t } = useTranslation();

  const dateFormatList = ["DD/MM/YYYY"];

  useEffect(() => {
    const tableFilters = filters[tableId] || {};
    if (tableFilters.searchText && tableFilters.searchedColumn) {
      setSearchText(tableFilters.searchText);
      setSearchedColumn(tableFilters.searchedColumn);
    }
  }, [filters, tableId]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    saveFilters(tableId, {
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText([]);
    setSearchedColumn([]);
    saveFilters(tableId, { searchText: [], searchedColumn: [] });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={searchInput}
          placeholder={`Rechercher par ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 110,
            }}>
            {t("Rechercher")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm();
            }}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "InfosLibres") {
        return record?.InfosLibres[0]?.Value?.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /** Recherche par date */
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <RangePicker
          allowClear={false}
          format={dateFormatList}
          // placeholder={`Rechercher par ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(date) => {
            setSelectedKeys(date ? [date] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "flex",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 110,
            }}>
            {t("Rechercher")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm();
            }}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (value) {
        const dateDoc = new Date(
          parseInt(record.DateCreation.split("(")[1].split(")")[0])
        ).getTime();

        // Initialise la date de début à minuit
        const startOfDay = (date) => {
          const newDate = new Date(date);
          newDate.setHours(0, 0, 0, 0);
          return newDate.getTime();
        };

        // Initialise la date de fin à minuit
        const endOfDay = (date) => {
          const newDate = new Date(date);
          newDate.setHours(23, 59, 59, 999);
          return newDate.getTime();
        };

        const startDate = value[0] ? startOfDay(value[0]) : null;
        const endDate = value[1] ? endOfDay(value[1]) : null;

        return startDate && endDate ? dateDoc >= startDate && dateDoc <= endDate : true;
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleCloseModal = () => {
    setIsModalFacturesOpen(false);
  };

  const showModalFactures = () => {
    setIsModalFacturesOpen(true);
  };

  const columns = [
    {
      title: "N° Doc",
      dataIndex: "NumeroDocument",
      filteredValue:
        filters[tableId] && filters[tableId].searchedColumn === "NumeroDocument"
          ? [filters[tableId].searchText]
          : [],
      width: 125,
      ...getColumnSearchProps("NumeroDocument"),
      render: (NumeroDocument) => NumeroDocument,
      // responsive: ["xl"],
    },
    {
      title: "Type",
      dataIndex: "TypeDocument",
      render: (TypeDocument) => {
        if (TypeDocument === 0) return "Devis";
        if (TypeDocument === 1) return t("Bon de commande");
        if (TypeDocument === 2) return t("Prepa. de livraison");
        if (TypeDocument === 3) return t("Bon de livraison");
        if (TypeDocument === 4) return "Bon de retour";
        if (TypeDocument === 5) return "Bon avoir";
        if (TypeDocument === 6) return t("Facture");
        if (TypeDocument === 7) return t("Facture comptabilisée");
      },
      responsive: ["xl"],
    },
    {
      title: "Client",
      dataIndex: "Intitule",
      filteredValue:
        filters[tableId] && filters[tableId].searchedColumn === "Intitule"
          ? [filters[tableId].searchText]
          : [],
      ...getColumnSearchProps("Intitule"),
      width: viewportWidth < 1081 ? 200 : "",
      render: (Intitule) => Intitule,
    },
    {
      title: "Note",
      dataIndex: "InfosLibres",
      filteredValue:
        filters[tableId] && filters[tableId].searchedColumn === "InfosLibres"
          ? [filters[tableId].searchText]
          : [],
      ...getColumnSearchProps("InfosLibres"),
      render: (InfosLibres) => InfosLibres[0]?.Value,
      width: viewportWidth < 1081 ? 200 : "",
      responsive: ["md"],
    },
    {
      title: t("Montant"),
      width: 100,
      dataIndex: "NetAPayer",
      render: (NetAPayer) => `${NetAPayer}€`,
    },
    {
      title: "Date",
      width: 150,
      dataIndex: "DateCreation",
      filteredValue:
        filters[tableId] && filters[tableId].searchedColumn === "DateCreation"
          ? [filters[tableId].searchText]
          : [],
      ...getColumnSearchDateProps("DateCreation"),
      render: (DateCreation) =>
        new Date(parseInt(DateCreation.split("(")[1].split(")")[0])).toLocaleDateString("fr-FR"),
    },
  ];

  return (
    <>
      <Button onClick={() => getDocumentsByRepresentant(user?.repId, 7)}>{t("Rafraîchir")}</Button>
      <Table
        columns={columns}
        rowKey={(record) => record.NumeroDocument}
        dataSource={facturesComptabiliseesAgent ? facturesComptabiliseesAgent : []}
        pagination={{ size: "small", position: ["bottomCenter"] }}
        scroll={{
          y: viewportWidth < 400 ? "68vh" : viewportWidth < 1081 ? "67vh" : "69vh",
        }}
        onRow={(doc) => {
          return {
            onClick: () => {
              console.log("click ", doc);
              setClickedBon(doc);
              showModalFactures();
            },
          };
        }}
      />
      <ModalHistoriqueFacture
        open={isModalFacturesOpen}
        onOk={handleCloseModal}
        detailsBon={clickedBon}
      />
    </>
  );
};

export default HistoriqueFacturesComptabilisees;
