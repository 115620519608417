import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  fr: {
    translation: {
      /** Toasts */
      "Aucune commande dans la file d'attente !": "Aucune commande dans la file d'attente !",
      "Veuillez d'abord envoyer ou mettre en attente votre commande actuelle":
        "Veuillez d'abord envoyer ou mettre en attente votre commande actuelle",
      "Erreur réseau. Réessayez quand votre connexion sera meilleure.":
        "Erreur réseau. Réessayez quand votre connexion sera meilleure.",

      /** Menu */
      Produits: "Produits",
      "Tous les produits": "Tous les produits",
      PLV: "PLV",
      Accessoires: "Accessoires",
      "Tous les clients": "Tous les clients",
      Créer: "Créer",
      Historique: "Historique",
      "Bons de commande": "Bons de commande",
      "Prepa. de Livraison": "Prepa. de livraison",
      "Bons de Livraison": "Bons de livraison",
      Factures: "Factures",
      "Factures Comptabilisées": "Factures comptabilisées",
      Downloads: "Téléchargements",
      "Waitlist orders": "Cmds en attente",

      /** Page produits */
      Disponible: "Disponible",
      Indisponible: "Indisponible",
      "Produit ajouté au panier": "Produit ajouté au panier !",

      /** Filtres */
      Filters: "Filtres",
      DISPONIBLES: "DISPONIBLES",
      NOUVEAUTES: "NOUVEAUTES",

      /** Page details produit */
      Marque: "Marque",
      Couleur: "Couleur",
      "Ajouter au panier": "Ajouter au panier",
      "Quantité mise à jour": "Quantité mise à jour",
      "Produit supprimé du panier": "Produit supprimé du panier",

      /** Page clients */
      Intitule: "Intitule",
      Ville: "Ville",
      Téléphone: "Téléphone",
      "Code postal": "Code postal",
      "Représentant, Id": "Représentant, Id",
      "Rechercher par": "Rechercher par",
      Rechercher: "Rechercher",
      "Aucun client": "Aucun client",

      /** Page details client */
      "Id client": "Id client",
      "Client depuis": "Client depuis",
      "Dernière commande": "Dernière commande",
      "Modifier le client": "Modifier le client",
      "Plus d'infos": "Plus d'infos",
      VOLUME: "VOLUME COMMANDÉ",
      "CHIFFRE D'AFFAIRE": "CHIFFRE D'AFFAIRE FACTURÉ",
      CA: "CA FACTURÉ",
      "RESTANT DÛ (N)": "RESTANT DÛ (N)",
      "RESTANT DÛ (N-1)": "RESTANT DÛ (N-1)",
      "Ref. Facture": "Ref. Facture",
      "Net à payer": "Net à payer",
      "Montant reglé": "Montant reglé",
      "RELIQUATS / EN ATTENTE": "RELIQUATS / EN ATTENTE (12 MOIS)",
      "BEST SELLER (ANNÉE EN COURS)": "BEST SELLER ANNÉE EN COURS",
      "BEST SELLER": "BEST SELLER (EN COURS)",
      Fermer: "Fermer",
      Qté: "Qté",
      "N° Facture": "N° Facture",
      Référence: "Référence",
      "Date échéance": "Date échéance",
      "ARRIVÉES À ÉCHÉANCE": "ARRIVÉES À ÉCHÉANCE (TTC)",
      "Année en cours": "Année en cours",
      "Année dernière": "Année dernière",
      "HISTORIQUE COMMANDES": "HISTORIQUE COMMANDES",
      "TOTAL DÛ": "TOTAL DÛ (TTC)",
      "Montant Total": "Montant Total",
      "Bons de livraison": "Bons de livraison",
      "Factures comptabilisées": "Factures comptabilisées",

      /** Form create / update client */
      "Intitulé du magasin": "Intitulé du magasin",
      Adresse: "Adresse",
      "Complément d'adresse": "Complément d'adresse",
      Pays: "Pays",
      "Site Web": "Site Web",
      "Mode de règlement": "Mode de règlement",
      Virement: "Virement",
      "Prélèvement CB": "Prélèvement CB",
      "Prélèvement SEPA": "Prélèvement SEPA",
      "Numéro de SIRET": "Numéro de SIRET",
      "Numéro de TVA": "Numéro de TVA",
      Commentaire: "Commentaire",
      "35 caractères maximum": "35 caractères maximum",
      Modifier: "Modifier",
      Réinitialiser: "Réinitialiser",
      France: "France",
      Danemark: "Danemark",
      Norvège: "Norvège",
      Suède: "Suède",
      Canada: "Canada",
      USA: "Etats-Unis",
      Allemagne: "Allemagne",
      "Pays-Bas": "Pays-Bas",
      Suisse: "Suisse",
      "Royaume-Uni": "Royaume-Uni",
      Espagne: "Espagne",
      Italie: "Italie",
      Australie: "Australie",
      Portugal: "Portugal",
      Maroc: "Maroc",
      Corée: "Corée",
      Algérie: "Algérie",
      "Arabie Saoudite": "Arabie Saoudite",
      Autriche: "Autriche",
      Belgique: "Belgique",
      Bénin: "Bénin",
      Brésil: "Brésil",
      Chine: "Chine",
      "Cote D'Ivoire": "Cote D'Ivoire",
      "Emirats Arabes Unis": "Emirats Arabes Unis",
      Estonie: "Estonie",
      Finlande: "Finlande",
      Gabon: "Gabon",
      Grèce: "Grèce",
      Guadeloupe: "Guadeloupe",
      Guatemala: "Guatemala",
      Guernesay: "Guernesay",
      "Hong Kong": "Hong Kong",
      "Ile Maurice": "Ile Maurice",
      "Iles Canaries": "Iles Canaries",
      Irlande: "Irlande",
      Israël: "Israël",
      Japon: "Japon",
      Kenya: "Kenya",
      Lettonie: "Lettonie",
      Liban: "Liban",
      Liechtenstein: "Liechtenstein",
      Luxembourg: "Luxembourg",
      Martinique: "Martinique",
      "Nouvelle-Calédonie": "Nouvelle-Calédonie",
      Paraguay: "Paraguay",
      Pologne: "Pologne",
      "Polynésie française": "Polynésie française",
      "République Dominicaine": "République Dominicaine",
      Réunion: "Réunion",
      Roumanie: "Roumanie",
      Russie: "Russie",
      Sénégal: "Sénégal",
      Slovaquie: "Slovaquie",
      Togo: "Togo",
      Turquie: "Turquie",
      // Pays Anglais vers Français pour envoyer le nom français à l'API
      Denmark: "Danemark",
      Norway: "Norvège",
      Sweden: "Suède",
      Germany: "Allemagne",
      Netherlands: "Pays-Bas",
      Switzerland: "Suisse",
      "United Kingdom": "Royaume-Uni",
      Spain: "Espagne",
      Italy: "Italie",
      Australia: "Australie",
      Morocco: "Maroc",
      Korea: "Corée",
      Algeria: "Algérie",
      "Saudi Arabia": "Arabie Saoudite",
      Austria: "Autriche",
      Belgium: "Belgique",
      Benin: "Bénin",
      Brazil: "Brésil",
      China: "Chine",
      "Ivory Coast": "Cote D'Ivoire",
      "United Arab Emirates": "Emirats Arabes Unis",
      Estonia: "Estonie",
      Finland: "Finlande",
      Greece: "Grèce",
      Guernsey: "Guernesay",
      Mauritius: "Ile Maurice",
      "Canary Islands": "Iles Canaries",
      Ireland: "Irlande",
      Israel: "Israël",
      Japan: "Japon",
      Latvia: "Lettonie",
      Lebanon: "Liban",
      "New Caledonia": "Nouvelle-Calédonie",
      Poland: "Pologne",
      "French Polynesia": "Polynésie française",
      "Dominican Republic": "République Dominicaine",
      Reunion: "Réunion",
      Romania: "Roumanie",
      Russia: "Russie",
      Senegal: "Sénégal",
      Slovakia: "Slovaquie",
      Turkey: "Turquie",

      /** Pages historique */
      Montant: "Montant",
      "Bon de commande": "Bon de commande",
      "BON DE COMMANDE": "BON DE COMMANDE",
      "Prepa. de livraison": "Prepa. de livraison",
      "PREPARATION DE LIVRAISON": "PREPARATION DE LIVRAISON",
      "Bon de livraison": "Bon de livraison",
      "BON DE LIVRAISON": "BON DE LIVRAISON",
      Facture: "Facture",
      FACTURE: "FACTURE",
      "Facture comptabilisée": "Facture comptabilisée",
      "FACTURE COMPTABILISEE": "FACTURE COMPTABILISEE",
      Prix: "Prix",

      /** Panier */
      Panier: "Panier",
      "Quantité totale": "Quantité totale",
      "Valider la commande": "Valider la commande",
      "File d'attente": "File d'attente",
      "Intitule produit": "Produit",
      Supp: "Supp",
      Supprimer: "Supprimer",
      Optiques: "Optiques",
      Solaires: "Solaires",
      "Date de livraison": "Date de livraison",
      Représentant: "Représentant",
      "Votre panier est vide": "Votre panier est vide",
      "Veuillez ne pas dépasser 300 caractères dans le commentaire":
        "Veuillez ne pas dépasser 300 caractères dans le commentaire",

      /** Dashboard agent */
      "CA EN COURS": "CA (EN COURS)",
      "CHIFFRE D'AFFAIRE ANNÉE EN COURS": "CHIFFRE D'AFFAIRE (ANNÉE EN COURS)",
      "CA N-1": "CA (N-1)",
      "CHIFFRE D'AFFAIRE ANNEE N-1": "CHIFFRE D'AFFAIRE (ANNEE N-1)",
      "NVX CLIENTS": "NVX CLIENTS",
      "NOUVEAUX CLIENTS (ANNÉE EN COURS)": "NOUVEAUX CLIENTS (ANNÉE EN COURS)",
      "Nouvel Objectif": "Nouvel Objectif",
      "Entrez un objectif": "Entrez un objectif",
      "Objectif actuel": "Objectif actuel",
      "Chiffre d'affaire": "Chiffre d'affaire (12 mois)",
      Actifs: "Actifs",
      Inactifs: "Inactifs",
      RELIQUAT: "RELIQUAT (12 MOIS)",
      "RELIQUAT TOTAL": "RELIQUAT TOTAL (12 MOIS)",
      "VOL. MONTURE EN COURS": "VOL. MONTURE (EN COURS)",
      "VOLUME MONTURE ANNÉE EN COURS": "VOLUME MONTURE (ANNÉE EN COURS)",
      "VOL. MONTURE N-1": "VOL. MONTURE (N-1)",
      "VOLUME MONTURE ANNÉE N-1": "VOLUME MONTURE (ANNÉE N-1)",

      /** Downloads page */
      "SEPA FRANÇAIS": "SEPA FRANÇAIS",
      "SEPA ANGLAIS": "SEPA ANGLAIS",
      "SEPA ALLEMAND": "SEPA ALLEMAND",
      Download: "Télécharger",

      /** Waitlist orders page */
      "Commande envoyée avec succès": "Commande envoyée avec succès !",
      Rafraîchir: "Rafraîchir",
      Envoyer: "Envoyer",
      "Veuillez choisir un client": "Veuillez choisir un client",

      /** Error page */
      "Sorry, an unexpected error has occurred.": "Désolé, une erreur s'est produite.",
      "Back to home": "Retour à l'accueil",
    },
  },
  en: {
    translation: {
      /** Toasts */
      "Aucune commande dans la file d'attente !": "No order in the queue!",
      "Veuillez d'abord envoyer ou mettre en attente votre commande actuelle":
        "Please send or queue your current order first.",
      "Erreur réseau. Réessayez quand votre connexion sera meilleure.":
        "Network error. Try again when your connection is better.",

      /** Menu */
      Produits: "Products",
      "Tous les produits": "All products",
      PLV: "POP Material",
      Accessoires: "Accessories",
      "Tous les clients": "All clients",
      Créer: "Create",
      Historique: "History",
      "Bons de commande": "Order forms",
      "Prepa. de Livraison": "Delivery prep.",
      "Bons de Livraison": "Delivery notes",
      Factures: "Invoices",
      "Factures Comptabilisées": "Invoices recorded",
      Downloads: "Downloads",
      "Waitlist orders": "Waitlist orders",

      /** Page produits */
      Disponible: "Available",
      Indisponible: "Unavailable",
      "Produit ajouté au panier": "Product successfully added to cart !",
      "Quantité mise à jour": "Updated quantity",
      "Produit supprimé du panier": "Product removed from the cart",

      /** Filtres */
      Filters: "Filters",
      DISPONIBLES: "AVAILABLES",
      NOUVEAUTES: "NEWS",

      /** Page details produit */
      Marque: "Brand",
      Couleur: "Color",
      "Ajouter au panier": "Add to cart",

      /** Page clients */
      Intitule: "Name",
      Ville: "City",
      Téléphone: "Phone",
      "Code postal": "Zipcode",
      "Représentant, Id": "Representative, Id",
      "Rechercher par": "Search by",
      Rechercher: "Search",
      "Aucun client": "No client",

      /** Page details client */
      "Id client": "Client Id",
      "Client depuis": "Client since",
      "Dernière commande": "Last order",
      "Modifier le client": "Update client",
      "Plus d'infos": "More details",
      VOLUME: "VOLUME ORDERED",
      "CHIFFRE D'AFFAIRE": "INVOICED REVENUE",
      CA: "INV. REVENUE ",
      "RESTANT DÛ (N)": "OUTSTANDING BALANCE (CURR.YEAR)",
      "RESTANT DÛ (N-1)": "OUTSTANDING BALANCE (LAST YEAR)",
      "Ref. Facture": "Ref. Invoice",
      "Net à payer": "Net balance payable",
      "Montant reglé": "Paid amount",
      "RELIQUATS / EN ATTENTE": "BACKORDER / PENDING (12 MONTHS)",
      "BEST SELLER (ANNÉE EN COURS)": "BEST SELLER CURRENT YEAR",
      "BEST SELLER": "BEST SELLER (CURR. YEAR)",
      Fermer: "Close",
      Qté: "Qty",
      "N° Facture": "Invoice Number",
      Référence: "Reference",
      "Date échéance": "Due date",
      "ARRIVÉES À ÉCHÉANCE": "INVOICES PAST DUE",
      "Année en cours": "Current year",
      "Année dernière": "Last year",
      "HISTORIQUE COMMANDES": "ORDERS HISTORY",
      "TOTAL DÛ": "TOTAL DUE",
      "Montant Total": "Total Amount",
      "Bons de livraison": "Delivery Notes",
      "Factures comptabilisées": "Invoices Recorded",

      /** Form create / update client */
      "Intitulé du magasin": "Store name",
      Adresse: "Address",
      "Complément d'adresse": "Additional address information",
      Pays: "Country",
      "Site Web": "Website",
      "Mode de règlement": "Payment method",
      Virement: "Bank transfer",
      "Prélèvement CB": "Credit card debit",
      "Prélèvement SEPA": "SEPA direct debit",
      "Numéro de SIRET": "SIRET number",
      "Numéro de TVA": "TVA number",
      Commentaire: "Comment",
      "35 caractères maximum": "35 characters maximum",
      Modifier: "Modify",
      Réinitialiser: "Reset",
      France: "France",
      Danemark: "Denmark",
      Norvège: "Norway",
      Suède: "Sweden",
      Canada: "Canada",
      USA: "USA",
      Allemagne: "Germany",
      "Pays-Bas": "Netherlands",
      Suisse: "Switzerland",
      "Royaume-Uni": "United Kingdom",
      Espagne: "Spain",
      Italie: "Italy",
      Australie: "Australia",
      Portugal: "Portugal",
      Maroc: "Morocco",
      Corée: "Korea",
      Algérie: "Algeria",
      "Arabie Saoudite": "Saudi Arabia",
      Autriche: "Austria",
      Belgique: "Belgium",
      Bénin: "Benin",
      Brésil: "Brazil",
      Chine: "China",
      "Cote D'Ivoire": "Ivory Coast",
      "Emirats Arabes Unis": "United Arab Emirates",
      Estonie: "Estonia",
      Finlande: "Finland",
      Gabon: "Gabon",
      Grèce: "Greece",
      Guadeloupe: "Guadeloupe",
      Guatemala: "Guatemala",
      Guernesay: "Guernsey",
      "Hong Kong": "Hong Kong",
      "Ile Maurice": "Mauritius",
      "Iles Canaries": "Canary Islands",
      Irlande: "Ireland",
      Israël: "Israel",
      Japon: "Japan",
      Kenya: "Kenya",
      Lettonie: "Latvia",
      Liban: "Lebanon",
      Liechtenstein: "Liechtenstein",
      Luxembourg: "Luxembourg",
      Martinique: "Martinique",
      "Nouvelle-Calédonie": "New Caledonia",
      Paraguay: "Paraguay",
      Pologne: "Poland",
      "Polynésie française": "French Polynesia",
      "République Dominicaine": "Dominican Republic",
      Réunion: "Reunion",
      Roumanie: "Romania",
      Russie: "Russia",
      Sénégal: "Senegal",
      Slovaquie: "Slovakia",
      Togo: "Togo",
      Turquie: "Turkey",

      /** Pages historique */
      Montant: "Amount",
      "Bon de commande": "Order form",
      "BON DE COMMANDE": "ORDER FORM",
      "Prepa. de livraison": "Delivery prep.",
      "PREPARATION DE LIVRAISON": "DELIVERY PREPARATION",
      "Bon de livraison": "Delivery note",
      "BON DE LIVRAISON": "DELIVERY NOTE",
      Facture: "Invoice",
      FACTURE: "INVOICE",
      "Facture comptabilisée": "Invoice recorded",
      "FACTURE COMPTABILISEE": "INVOICE RECORDED",
      Prix: "Price",

      /** Panier */
      Panier: "Cart",
      "Quantité totale": "Total quantity",
      "Valider la commande": "Validate order",
      "File d'attente": "Waitlist",
      "Intitule produit": "Product",
      Supp: "Del",
      Supprimer: "Delete",
      Optiques: "Optics",
      Solaires: "Solars",
      "Date de livraison": "Delivery date",
      Représentant: "Representative",
      "Votre panier est vide": "Your cart is empty",
      "Veuillez ne pas dépasser 300 caractères dans le commentaire":
        "Please do not exceed 300 characters in the comment",

      /** Dashboard agent */
      "CA EN COURS": "REVENUE (CURR. YEAR)",
      "CHIFFRE D'AFFAIRE ANNÉE EN COURS": "REVENUE (CURRENT YEAR)",
      "CA N-1": "REVENUE (PREV. YEAR)",
      "CHIFFRE D'AFFAIRE ANNEE N-1": "REVENUE (PREVIOUS YEAR)",
      "NVX CLIENTS": "NEW CLIENTS",
      "NOUVEAUX CLIENTS (ANNÉE EN COURS)": "NEW CLIENTS (CURRENT YEAR)",
      "Nouvel Objectif": "New Objective",
      "Entrez un objectif": "Enter your objective",
      "Objectif actuel": "Current objective",
      "Chiffre d'affaire": "Revenue (12 months)",
      Actifs: "Active",
      Inactifs: "Inactive",
      RELIQUAT: "BACKORDER (12 MONTHS)",
      "RELIQUAT TOTAL": "TOTAL BACKORDER (12 MONTHS)",
      "VOL. MONTURE EN COURS": "FRAME VOL. (CURR. YEAR)",
      "VOLUME MONTURE ANNÉE EN COURS": "FRAME VOLUME (CURRENT YEAR)",
      "VOL. MONTURE N-1": "FRAME VOL. (PREV. YEAR)",
      "VOLUME MONTURE ANNÉE N-1": "FRAME VOLUME (PREVIOUS YEAR)",

      /** Downloads page */
      "SEPA FRANÇAIS": "SEPA FRENCH",
      "SEPA ANGLAIS": "SEPA ENGLISH",
      "SEPA ALLEMAND": "SEPA DEUTSCH",
      Download: "Download",

      /** Waitlist orders page */
      "Commande envoyée avec succès": "Order successfully sent !",
      Rafraîchir: "Refresh",
      Envoyer: "Send",
      "Veuillez choisir un client": "Please select à client",

      /** Error page */
      "Sorry, an unexpected error has occurred.": "Sorry, an unexpected error has occurred.",
      "Back to home": "Back to home",
    },
  },
};

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
