import { Button, Card } from "antd";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { ModalDuClient } from "../modal-client-impayes";
import MainContext from "../../contexts/MainContext";

const DashboardClientTotalDu = ({ montantTotalDu, tabFactures }) => {
  const { t } = useTranslation();
  const [isModalImpayesOpen, setIsModalImpayesOpen] = useState(false);
  const totalMontantImpaye = Number(montantTotalDu);

  const { viewportWidth } = useContext(MainContext);

  const showModalImpayes = () => {
    setIsModalImpayesOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalImpayesOpen(false);
  };

  return (
    <Card
      title={t("TOTAL DÛ")}
      style={{ height: "100%" }}
      bodyStyle={
        viewportWidth < 1181
          ? {
              fontSize: "1.2rem",
              fontWeight: "bold",
              margin: 0,
              padding: "16px 4px",
            }
          : {
              fontSize: "1.6rem",
              fontWeight: "bold",
              margin: 0,
              padding: "16px 4px",
            }
      }>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        {!totalMontantImpaye === null ? (
          <Loader />
        ) : totalMontantImpaye && totalMontantImpaye > 0 ? (
          <p style={{ margin: 0, color: "red" }}>
            {totalMontantImpaye.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}{" "}
            €
          </p>
        ) : (
          <p style={{ margin: 0, color: "green" }}>
            {totalMontantImpaye.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}{" "}
            €
          </p>
        )}
        {tabFactures && tabFactures.length ? (
          <Button type="ghost" onClick={showModalImpayes}>
            {t("Plus d'infos")}
          </Button>
        ) : (
          ""
        )}
      </div>
      <ModalDuClient tabFactures={tabFactures} open={isModalImpayesOpen} onOk={handleCloseModal} />
    </Card>
  );
};

export default DashboardClientTotalDu;
