import { DeleteOutlined } from "@ant-design/icons";
import { useContext } from "react";
import CartContext from "../../contexts/CartContext";
import { Button } from "antd";

const deleteBtn = {
	backgroundColor: "#c0392b",
	border: "none",
	color: "white",
};

const DeleteProductInCart = ({ productId }) => {
	const { deleteCartItem } = useContext(CartContext);

	return (
		<Button onClick={() => deleteCartItem(productId)} style={deleteBtn}>
			<DeleteOutlined />
		</Button>
	);
};

export default DeleteProductInCart;
