import { Tag } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const PastilleDispo = ({ stockProduct, loadingStock, error, futureLivraison }) => {
  const { t } = useTranslation();
  const { viewportWidth } = useContext(MainContext);
  let content;
  const currentDate = new Date();

  if (loadingStock) {
    content = "...";
  } else if (error) {
    const tagContent = viewportWidth < 1090 ? "●" : t("Indisponible");
    content = <Tag color="red">{tagContent}</Tag>;
  } else if (stockProduct > 5) {
    const tagContent = viewportWidth < 1090 ? "●" : t("Disponible");
    content = <Tag color="green">{tagContent}</Tag>;
  } else if (stockProduct <= 5 && futureLivraison) {
    const formattedDate = new Date(
      parseInt(futureLivraison.DateLivraison?.split("(")[1]?.split(")")[0])
    );
    // Filtre sur la date => Si date passé afficher indisponible
    if (formattedDate < currentDate) {
      const tagContent = viewportWidth < 1090 ? "●" : t("Indisponible");
      content = <Tag color="red">{tagContent}</Tag>;
    } else {
      content = <Tag color="orange">{formattedDate.toLocaleDateString("fr-FR")}</Tag>;
    }
  } else {
    const tagContent = viewportWidth < 1090 ? "●" : t("Indisponible");
    content = <Tag color="red">{tagContent}</Tag>;
  }

  return content;
};

export default PastilleDispo;
