import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const ModalImpayes = ({ tabFacturesImpayesN, tabFacturesImpayesNMoinsUn, open, onOk, year }) => {
  const { viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <Modal
      title={t("ARRIVÉES À ÉCHÉANCE")}
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}>
      <Row>
        <Col xs={0} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
          Date
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "left" }}>
          {t("Intitulé du magasin")}
        </Col>
        <Col xs={6} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
          {t("N° Facture")}
        </Col>
        <Col xs={0} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
          {t("Référence")}
        </Col>
        <Col xs={6} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
          {t("Montant")}
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
          {t("Date échéance")}
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      <Row>
        {year === "n" && tabFacturesImpayesN && tabFacturesImpayesN.length > 0
          ? tabFacturesImpayesN.map((b) => (
              <Fragment key={b.Id}>
                <Col xs={0} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
                  {new Date(
                    parseInt(b.DateEcriture.split("(")[1].split(")")[0])
                  ).toLocaleDateString("fr-FR")}
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "left" }}>
                  {b.Intitule}
                </Col>
                <Col xs={6} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
                  {b.NumeroFacture}
                </Col>
                <Col xs={0} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                  {b.Reference}
                </Col>
                <Col xs={6} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
                  {b.NumeroFacture.includes("AV") && !b.Montant.toString().includes("-")
                    ? `-${b.Montant}`
                    : b.Montant}
                  €
                </Col>
                <Col xs={6} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
                  {new Date(
                    parseInt(b.DateEcheance.split("(")[1].split(")")[0])
                  ).toLocaleDateString("fr-FR")}
                </Col>
                <Divider />
              </Fragment>
            ))
          : ""}
        {year === "n-1" && tabFacturesImpayesNMoinsUn && tabFacturesImpayesNMoinsUn.length > 0
          ? tabFacturesImpayesNMoinsUn.map((b) => (
              <Fragment key={b.NumeroDocument}>
                <Col xs={0} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
                  {new Date(
                    parseInt(b.DateEcriture.split("(")[1].split(")")[0])
                  ).toLocaleDateString("fr-FR")}
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "left" }}>
                  {b.Intitule}
                </Col>
                <Col xs={6} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
                  {b.NumeroFacture}
                </Col>
                <Col xs={0} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                  {b.Reference}
                </Col>
                <Col xs={6} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
                  {b.NumeroFacture.includes("AV") && !b.Montant.toString().includes("-")
                    ? `-${b.Montant}`
                    : b.Montant}
                  €
                </Col>
                <Col xs={6} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
                  {new Date(
                    parseInt(b.DateEcheance.split("(")[1].split(")")[0])
                  ).toLocaleDateString("fr-FR")}
                </Col>
                <Divider />
              </Fragment>
            ))
          : ""}
      </Row>
    </Modal>
  );
};

export default ModalImpayes;
