const getRoute = (path) => {
  const pathnameTmp = path.pathname;
  const pathname = pathnameTmp.slice(1);
  return capitalizeFirstLetter(pathname);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatDateToW100 = (date = null) => {
  if (date !== null) {
    const deliveryDate = new Date(date);
    const timestamp = deliveryDate.getTime();
    const timezoneOffset = deliveryDate.getTimezoneOffset();
    const timezoneOffsetFormatted =
      (timezoneOffset > 0 ? "-" : "+") +
      String(Math.abs(timezoneOffset) / 60).padStart(2, "0") +
      "00";

    const formattedDate = `/Date(${timestamp}${timezoneOffsetFormatted})/`;
    return formattedDate;
  } else {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const timezoneOffset = currentDate.getTimezoneOffset();
    const timezoneOffsetFormatted =
      (timezoneOffset > 0 ? "-" : "+") +
      String(Math.abs(timezoneOffset) / 60).padStart(2, "0") +
      "00";

    const formattedDate = `/Date(${timestamp}${timezoneOffsetFormatted})/`;
    return formattedDate;
  }
};

// const formatGivenDateToW100 = (date) => {
//   if (date) {
//   }
// };

const changeCountryCodeToFlag = (role) => {
  const countryCodeBrut = role.split("rep")[1].trim().toUpperCase();
  let countryCode = "";

  /** A étoffer */
  if (countryCodeBrut === "ROMANDE") {
    countryCode = "CH";
  } else if (countryCodeBrut === "MURAKAMI") {
    countryCode = "JP";
  } else {
    countryCode = countryCodeBrut;
  }

  return (
    <>
      <img
        src={`/flags.svg#${countryCode}`}
        alt={`drapeau ${countryCode}`}
        width="32"
        height="21"
      />
      <span
        style={{ marginLeft: "8px" }}
        title={countryCodeBrut || countryCode}
      >
        {countryCodeBrut.slice(0, 2) || countryCode}
      </span>
    </>
  );
};

export {
  getRoute,
  capitalizeFirstLetter,
  formatDateToW100,
  // formatGivenDateToW100,
  changeCountryCodeToFlag,
};
