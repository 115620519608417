import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Button, Collapse, Divider } from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import CartContext from "../../contexts/CartContext";
import PriceContext from "../../contexts/PriceContext";

const WaitlistOrders = () => {
  const { user, accessTokenJwt, isLoadingAccessToken } =
    useContext(AuthContext);
  const { cart, addProductFromWaitingOrderToCart, setWaitingOrder } =
    useContext(CartContext);
  const { idDevise } = useContext(PriceContext);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [parsedOrders, setParsedOrders] = useState(null);

  const { t } = useTranslation();
  const { Panel } = Collapse;

  useEffect(() => {
    fetchPendingOrders();
  }, []);

  useEffect(() => {
    // console.log("PENDING ORDERS: ", pendingOrders);
    parseOrders(pendingOrders);
  }, [pendingOrders]);

  const fetchPendingOrders = () => {
    // Ouvrez la base de données
    const openRequest = indexedDB.open("postRequestsDB", 1);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("pending")) {
        db.createObjectStore("pending");
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;

      if (db.objectStoreNames.contains("pending")) {
        const transaction = db.transaction(["pending"], "readonly");
        const objectStore = transaction.objectStore("pending");
        const getRequest = objectStore.getAll();
        const getKeysRequest = objectStore.getAllKeys();

        getRequest.onsuccess = function (event) {
          const orders = event.target.result;

          getKeysRequest.onsuccess = function (keyEvent) {
            const keys = keyEvent.target.result;

            // Associate orders with their keys
            const ordersWithKeys = orders.map((order, index) => ({
              key: keys[index],
              data: order,
            }));

            setPendingOrders(ordersWithKeys);
          };
        };
      }
    };

    openRequest.onerror = function (event) {
      console.log("Erreur d'ouverture de la base de données", event);
    };
  };

  const deleteOrderFromIndexedDB = (key) => {
    const dbRequest = indexedDB.open("postRequestsDB", 1);

    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const tx = db.transaction(["pending"], "readwrite");
      const store = tx.objectStore("pending");

      // Supprimez la commande de l'object store
      const deleteRequest = store.delete(key);

      deleteRequest.onsuccess = () => {
        console.log("Commande supprimée de la file d'attente.");
      };

      deleteRequest.onerror = (event) => {
        console.log(
          "Une erreur s'est produite lors de la suppression de la commande."
        );
      };
    };

    dbRequest.onerror = (event) => {
      console.log(
        "Une erreur s'est produite lors de l'ouverture de la base de données."
      );
    };
  };

  const parseOrders = (pendingOrders) => {
    let ordersTab = [];

    if (pendingOrders && pendingOrders.length > 0) {
      pendingOrders.forEach((pendingOrder) => {
        const orderContent = JSON.parse(pendingOrder.data.body);
        const keyedOrderContent = { ...orderContent, key: pendingOrder.key }; // Ajout de la clé
        ordersTab.push(keyedOrderContent);
      });

      setParsedOrders(ordersTab);
    } else {
      setParsedOrders([]);
    }
  };

  const restoreOrderInCart = (order) => {
    // console.log(order);
    if (cart.length === 0) {
      setWaitingOrder(order);
      order.ligneList.forEach((article) => {
        addProductFromWaitingOrderToCart(article.product, article.qty);
      });

      // clearOrderInfos();
      deleteOrderFromIndexedDB(order.key);
      fetchPendingOrders();
    } else {
      toast.error(
        t(
          "Veuillez d'abord envoyer ou mettre en attente votre commande actuelle"
        )
      );
    }
  };

  const sendSavedOrder = async (order, accessTokenJwt) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/bon-commande/create`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessTokenJwt}`,
          "X-Is-Resend": "true",
        },
        body: JSON.stringify(order),
      }
    );
    return response;
  };

  const clickSendSavedOrderBtn = async (order, accessTokenJwt) => {
    if (isLoadingAccessToken) {
      toast.error("Vous n'êtes pas authentifié !");
      return;
    }

    const loadingToast = toast.loading("Chargement...");

    try {
      const response = await sendSavedOrder(order, accessTokenJwt);

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi de la commande");
      }

      toast.dismiss(loadingToast);
      toast.success(t("Commande envoyée avec succès"));

      // console.log("order key: ", order.key);

      deleteOrderFromIndexedDB(order.key);
      fetchPendingOrders();
    } catch (error) {
      toast.dismiss(loadingToast);

      if (
        error.message === "Failed to fetch" ||
        error.message === "NetworkError when attempting to fetch resource."
      ) {
        toast.error(
          t("Erreur réseau. Réessayez quand votre connexion sera meilleure.")
        );
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div>
      <Button onClick={fetchPendingOrders}>{t("Rafraîchir")}</Button>
      <div>
        {parsedOrders !== null && parsedOrders.length > 0 ? (
          <Collapse>
            {parsedOrders
              // .filter(
              //   (order) =>
              //     parseInt(order.document.IdCollaborateur) ===
              //     parseInt(user?.repId)
              // )
              .map((order) => {
                // console.log("order", order);
                return (
                  <Panel
                    key={order.key}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: "1 0" }}>
                          {order.document.Intitule}
                        </span>
                        <span style={{ flex: "2 0" }}>
                          {order.document.InfosLibres[0].Value}
                        </span>
                        <span
                          style={{
                            flex: "1 0",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {
                            new Date(
                              parseInt(
                                order.document.Date.split("(")[1].split(")")[0]
                              )
                            )
                              .toLocaleString()
                              .split(" ")[0]
                          }
                        </span>
                      </div>
                    }
                  >
                    <div>
                      {order.ligneList.map((article, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span style={{ flex: "3 0" }}>
                            {article.Intitule}
                          </span>
                          <span style={{ flex: "1 0" }}>
                            {article.PrixUnitaire}
                            {idDevise && idDevise === 3 ? "$" : "€"}
                          </span>
                          <span style={{ flex: "0 0" }}>
                            {article.Quantite}
                          </span>
                        </div>
                      ))}
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Total: <span>{order.document.TotalCart}€</span>
                      </div>
                    </div>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={() => restoreOrderInCart(order)}
                        style={{
                          backgroundColor: "#1890ff",
                          color: "white",
                          marginRight: "8px",
                        }}
                      >
                        <SyncOutlined /> Restaurer
                      </Button>
                      <Button
                        onClick={() => {
                          deleteOrderFromIndexedDB(order.key);
                          fetchPendingOrders();
                        }}
                        style={{
                          backgroundColor: "#c0392b",
                          color: "white",
                          marginRight: "8px",
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                      <Button
                        onClick={() =>
                          clickSendSavedOrderBtn(order, accessTokenJwt)
                        }
                        style={{ backgroundColor: "#6ab04c", color: "white" }}
                      >
                        {t("Envoyer")}
                      </Button>
                    </div>
                  </Panel>
                );
              })}
          </Collapse>
        ) : (
          t("Aucune commande dans la file d'attente !")
        )}
      </div>
    </div>
  );
};

export default WaitlistOrders;
