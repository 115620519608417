import { Form, Input, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./create-client.css";
import { toast } from "react-hot-toast";
import AuthContext from "../../contexts/AuthContext";
import MainContext from "../../contexts/MainContext";
import PriceContext from "../../contexts/PriceContext";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/functions";

const CreateClientPage = () => {
  const [clientInfos, setClientInfos] = useState({});
  const [pays, setPays] = useState("France");
  const [categorieComptable, setCategorieComptable] = useState(1);
  const [lastCLT, setLastCLT] = useState(null);
  const [billingTerms, setBillingTerms] = useState(null);

  const { user, accessTokenJwt, isLoadingAccessToken } = useContext(AuthContext);
  const { setCurrent, setClients } = useContext(MainContext);
  const { activeRole } = useContext(PriceContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    getLastCLT();
    createNewClt(lastCLT);
    // console.log(lastCLT);
  }, [lastCLT]);

  useEffect(() => {
    const getBillingTerms = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/billingTerms`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      const data = await response.json();
      setBillingTerms(data);
    };
    getBillingTerms();
  }, []);

  const CreerClientBtn = {
    backgroundColor: "#6ab04c",
    width: "100%",
    padding: "12px",
    border: "none",
    color: "white",
    fontSize: "1.4rem",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const validateMessages = {
    required: "${label} est requis !",
    types: {
      email: "${label} n'est pas un email valide !",
      number: "${label} n'est pas un chiffre valide !",
    },
  };

  const handleCategorieComptable = (paysValue) => {
    let ct = 1;
    if (paysValue === 1) {
      ct = 1;
    }
    // Tous les pays de l'Union Européenne définit dans le select
    else if (
      [
        2, 2.2, 5, 5.1, 8, 9, 13, 5.2, 1.3, 2.3, 2.4, 12.1, 7.1, 2.5, 1.16, 2.6, 2.9, 2.7, 2.8,
      ].includes(paysValue)
    ) {
      ct = 2;
    } else {
      ct = 3;
    }
    setCategorieComptable(ct);
  };

  const handleMRChange = (value) => {
    console.log(value);
  };

  const savePaysLabel = (value, option) => {
    console.log(value, option);
    setPays(option?.label);
    handleCategorieComptable(value);
  };

  const onValuesChange = async (changedValues, allValues) => {
    setClientInfos({
      ...allValues.client,
      repId: user.repId,
      numeroTiers: await createNewClt(lastCLT),
    });
    // console.log(allValues, clientInfos);
  };

  const onFinish = async () => {
    if (
      clientInfos.intitule &&
      clientInfos.adresse &&
      // clientInfos.codePostal &&
      clientInfos.ville &&
      clientInfos.pays &&
      clientInfos.contact &&
      clientInfos.telephone &&
      clientInfos.email &&
      clientInfos.modeReglement &&
      clientInfos.numeroTiers &&
      clientInfos.repId &&
      !isLoadingAccessToken
    ) {
      const loadingToast = toast.loading("Chargement...");
      const translatedCountry = t(pays, { lng: "fr" });
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/create`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessTokenJwt}`,
          },
          body: JSON.stringify({
            tiers: {
              __type: "Client:http://www.proconsult.lu/WebServices100",
              TypeTiers: 0,
              NumeroTiers: clientInfos.numeroTiers,
              NumeroTiersPayeur: clientInfos.numeroTiers,
              IdCategorieComptable: categorieComptable,
              // parseInt(Math.floor(clientInfos.pays)) === 1 ? 2 : 3,
              IdDevise:
                parseInt(Math.floor(clientInfos.pays)) === 3
                  ? 3
                  : parseInt(Math.floor(clientInfos.pays)) === 4
                  ? 14
                  : 0,
              IdCategorieTarifaire: parseInt(Math.floor(clientInfos.pays)),
              IdConditionLivraison: 1,
              IdModeExpedition: 1,
              IdModeleReglement: parseInt(clientInfos.modeReglement),
              Intitule: clientInfos.intitule?.toUpperCase(),
              Qualite: "",
              Abrege: clientInfos.intitule?.toUpperCase(),
              Contact: clientInfos.contact,
              Adresse: clientInfos.adresse,
              Complement: clientInfos.complement ?? "",
              CodePostal: clientInfos.codePostal ?? "",
              Ville: capitalizeFirstLetter(clientInfos.ville),
              Region: "",
              Pays: translatedCountry,
              Telephone: clientInfos.telephone,
              Fax: "",
              Email: clientInfos.email,
              SiteWeb: clientInfos.siteWeb ?? "",
              Statistique03: clientInfos.billingTerms ?? "",
              CodeEdi: "",
              CodeNAF: "",
              Commentaire: "NOUVEAU CLIENT",
              Langue: 0,
              EstEnSommeil: false,
              Statistique01: "",
              TauxReleve: 0,
              TauxRFA: 0,
              Facture: 1,
              Saut: true,
              Analytique: 0,
              CodeAffaire: null,
              IdRepresentant: parseInt(clientInfos.repId),
              NumeroSiret: clientInfos.numeroSIRET ?? "",
              NumeroTva: clientInfos.numeroTVA ?? "",
            },
          }),
        });

        if (response.status !== 200) {
          toast.dismiss(loadingToast);
          toast.error("Une erreur est survenue ! \nCode erreur: " + response.status);
        } else {
          const newClient = await response.json();

          toast.dismiss(loadingToast);
          // console.log("Validé !", clientInfos);
          toast.success("Client créé avec succès !");

          // console.log("new clients: ", newClient);
          setClients((prevClients) => [...prevClients, newClient]);

          setTimeout(() => {
            navigate("/clients");
            setCurrent("/clients");
          }, 2000);
        }
      } catch (error) {
        toast.dismiss(loadingToast);
        toast.error("Une erreur est survenue ! \n" + error);
        // console.log("erreur", error);
      }
    } else {
      toast.error("Tous les champs obligatoires sont requis !");
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    toast.error("Tous les champs obligatoires sont requis !");
  };

  const getLastCLT = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/lastCLTClient`, {
      method: "GET",
    });
    const lastCLT = await response.json();
    // console.log(lastCLT?.NumeroTiers);
    setLastCLT(lastCLT?.NumeroTiers);
  };

  /** Ptite fonction récursive pour tester les Numéros Tiers (CLT) */
  const createNewClt = async (lastCLT) => {
    let oldClt = await lastCLT;
    let numClt = parseInt(oldClt?.split("T")[1]);

    if (typeof numClt === "number" && !isNaN(numClt)) {
      // console.log(numClt);
      numClt += 1;
      let newClt = `CLT${numClt}`;

      /**Check si dispo */
      const isCltAvailablePromise = await fetch(
        `${process.env.REACT_APP_API_URL}/clients/is-clt-available/${newClt}`,
        {
          method: "GET",
        }
      );
      const isCltAvailable = await isCltAvailablePromise.json();
      // console.log("clt dispo ?", newClt, isCltAvailable);

      if (isCltAvailable !== false) {
        // console.log(newClt);
        return newClt;
      } else {
        return await createNewClt(newClt);
      }
    }
  };

  return (
    <>
      <div className="create-client__form-container">
        <Form
          validateMessages={validateMessages}
          layout="vertical"
          style={{ width: "100%" }}
          initialValues={{
            remember: true,
            client: { pays: 1 },
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}>
          <Form.Item
            name={["client", "intitule"]}
            label={t("Intitulé du magasin")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>

          <Form.Item
            name={["client", "adresse"]}
            label={t("Adresse")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "complement"]}
            label={t("Complément d'adresse")}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "codePostal"]}
            label={t("Code postal")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input type="number" min="0" max="99999" maxLength={5} />
          </Form.Item>

          <Form.Item
            name={["client", "ville"]}
            label={t("Ville")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "pays"]}
            label={t("Pays")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Select
              allowClear
              showSearch
              placeholder={t("Pays")}
              onChange={savePaysLabel}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
              }
              options={[
                { value: 1, label: t("France") },
                { value: 2, label: t("Danemark") },
                { value: 2.1, label: t("Norvège") },
                { value: 2.2, label: t("Suède") },
                { value: 3, label: t("Canada") },
                { value: 4, label: t("USA") },
                { value: 5, label: t("Allemagne") },
                { value: 5.1, label: t("Pays-Bas") },
                { value: 6, label: t("Suisse") },
                { value: 7, label: t("Royaume-Uni") },
                { value: 8, label: t("Espagne") },
                { value: 9, label: t("Italie") },
                { value: 10, label: t("Australie") },
                { value: 13, label: t("Portugal") },
                { value: 14, label: t("Maroc") },
                { value: 15, label: t("Corée") },
                { value: 1.1, label: t("Algérie") },
                { value: 1.2, label: t("Arabie Saoudite") },
                { value: 5.2, label: t("Autriche") },
                { value: 1.3, label: t("Belgique") },
                { value: 1.4, label: t("Bénin") },
                { value: 1.5, label: t("Brésil") },
                { value: 12, label: t("Chine") },
                { value: 1.6, label: t("Cote D'Ivoire") },
                { value: 1.7, label: t("Emirats Arabes Unis") },
                { value: 2.3, label: t("Estonie") },
                { value: 2.4, label: t("Finlande") },
                { value: 1.8, label: t("Gabon") },
                { value: 12.1, label: t("Grèce") },
                { value: 11, label: t("Guadeloupe") },
                { value: 1.9, label: t("Guatemala") },
                { value: 1.23, label: t("Guernesay") },
                { value: 12.2, label: t("Hong Kong") },
                { value: 11.1, label: t("Ile Maurice") },
                { value: 11.2, label: t("Iles Canaries") },
                { value: 7.1, label: t("Irlande") },
                { value: 1.11, label: t("Israël") },
                { value: 1.12, label: t("Japon") },
                { value: 1.13, label: t("Kenya") },
                { value: 2.5, label: t("Lettonie") },
                { value: 1.14, label: t("Liban") },
                { value: 1.15, label: t("Liechtenstein") },
                { value: 1.16, label: t("Luxembourg") },
                { value: 11.3, label: t("Martinique") },
                { value: 11.4, label: t("Nouvelle-Calédonie") },
                { value: 1.17, label: t("Paraguay") },
                { value: 2.6, label: t("Pologne") },
                { value: 11.5, label: t("Polynésie française") },
                { value: 1.18, label: t("République Dominicaine") },
                { value: 2.9, label: t("République Tchèque") },
                { value: 11.6, label: t("Réunion") },
                { value: 2.7, label: t("Roumanie") },
                { value: 12.3, label: t("Russie") },
                { value: 1.19, label: t("Sénégal") },
                { value: 2.8, label: t("Slovaquie") },
                { value: 1.22, label: t("Togo") },
                { value: 1.21, label: t("Turquie") },
              ]}></Select>
          </Form.Item>

          <Form.Item
            name={["client", "contact"]}
            label="Contact"
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "telephone"]}
            label={t("Téléphone")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "email"]}
            label="Email"
            rules={[{ required: true, type: "email" }]}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "siteWeb"]}
            label={t("Site Web")}
            style={{ marginBottom: "8px" }}>
            <Input />
          </Form.Item>
          <Form.Item
            name={["client", "modeReglement"]}
            label={t("Mode de règlement")}
            rules={[{ required: true }]}
            style={{ marginBottom: "8px" }}>
            <Select
              placeholder={t("Mode de règlement")}
              onChange={handleMRChange}
              options={[
                { value: 23, label: `${t("Virement")}` },
                { value: 5, label: `${t("Prélèvement CB")}` },
                { value: 18, label: `${t("Prélèvement SEPA")}` },
              ]}></Select>
          </Form.Item>
          {/* Non obligatoire coté Sage */}
          {pays === "France" ? (
            <>
              <Form.Item
                name={["client", "numeroSIRET"]}
                label={t("Numéro de SIRET")}
                rules={[{ required: true }]}
                style={{ marginBottom: "8px" }}>
                <Input />
              </Form.Item>
              <Form.Item
                name={["client", "numeroTVA"]}
                label={t("Numéro de TVA")}
                rules={[{ required: true }]}
                style={{ marginBottom: "8px" }}>
                <Input />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name={["client", "numeroTVA"]}
              label={t("Numéro de TVA")}
              rules={[{ required: true }]}
              style={{ marginBottom: "8px" }}>
              <Input />
            </Form.Item>
          )}

          {activeRole === "repUs" && pays === "Etats-Unis" && billingTerms?.length > 0 ? (
            <Form.Item
              name={["client", "billingTerms"]}
              label={t("Billing Terms")}
              rules={[{ required: false }]}
              style={{ marginBottom: "8px" }}>
              <Select
                allowClear
                showSearch
                placeholder={t("Billing Terms")}
                // onChange={savePaysLabel}
                options={billingTerms?.map((bt) => ({
                  value: bt.Intitule,
                  label: bt.Intitule,
                }))}></Select>
            </Form.Item>
          ) : (
            <></>
          )}

          {/* <Form.Item
            name={["client", "commentaire"]}
            label={t("Commentaire")}
            style={{ marginBottom: "8px" }}
          >
            <TextArea
              rows={4}
              maxLength={35}
              placeholder={t("35 caractères maximum")}
            />
          </Form.Item> */}
          <button style={CreerClientBtn}>
            {t("Créer")} <CheckOutlined />
          </button>
        </Form>
      </div>
    </>
  );
};

export default CreateClientPage;
