import { createContext, useContext, useEffect, useState } from "react";
import AuthContext from "./AuthContext";

const PriceContext = createContext({});

const Provider = ({ children }) => {
  const [idDevise, setIdDevise] = useState(0);
  const [categorieTarifaire, setCategorieTarifaire] = useState(1);
  const { user } = useContext(AuthContext);
  const [rolesUser, setRolesUser] = useState([]);
  const [activeRole, setActiveRole] = useState("");

  useEffect(() => {
    if (user) {
      if (user?.role.includes(",")) {
        console.log("user?.role: ", user?.role);
        const rolesUserTab = user?.role.split(",");
        let rolesTmp = [];
        rolesUserTab.forEach((role) => {
          rolesTmp.push(role.trim());
        });
        setRolesUser(rolesTmp);
        setActiveRole(rolesTmp[0]);
      } else {
        setActiveRole(user?.role);
      }
    }
  }, [user]);

  useEffect(() => {
    const config = roleConfig[activeRole] || {
      idDevise: 0,
    };
    console.log("active role: ", activeRole);

    setIdDevise(config.idDevise);
    setCategorieTarifaire(getCategorieTarifaireByRep(activeRole));
  }, [activeRole]);

  useEffect(() => {
    console.log("CAT TARIFAIRE: ", categorieTarifaire);
  }, [categorieTarifaire]);

  function getCategorieTarifaireByRep(role) {
    if (!role) {
      return 1;
    }

    switch (role) {
      // case "repEs":
      case "repBe":
      case "repFr":
        return 1;
      case "repDomTom":
        return 11;
      case "repDe":
      case "repNl":
        return 5;
      case "repSe":
      case "repNo":
      case "repDk":
      // Tous les plus 10 (temporaire)
      case "repCh":
      case "repAf":
      case "repPl":
      case "repJp":
        return 2;
      // Fin tous les plus 10 (temporaire)
      case "repIt":
        return 9;
      case "repRomande": // Berdoz
        return 6;
      case "repCa":
        return 3;
      case "repKr":
        return 15;
      case "repMurakami":
        return 12;
      case "repUs":
        return 4;
      case "repUk":
        return 7;
      default:
        return 1;
    }
  }

  const roleConfig = {
    repBe: { idDevise: 0 },
    repFr: { idDevise: 0 },
    repDomTom: { idDevise: 0 },
    repCh: { idDevise: 0 },
    repCa: { idDevise: 3 },
    repEs: { idDevise: 0 },
    repIt: { idDevise: 0 },
    repKr: { idDevise: 0 },
    repJp: { idDevise: 0 },
    repDe: { idDevise: 0 },
    repNl: { idDevise: 0 },
    repSe: { idDevise: 0 },
    repNo: { idDevise: 0 },
    repUs: { idDevise: 14 },
    repRomande: { idDevise: 0 },
    repMurakami: { idDevise: 0 },
  };

  return (
    <PriceContext.Provider
      value={{
        idDevise,
        rolesUser,
        activeRole,
        setActiveRole,
        categorieTarifaire,
      }}>
      {children}
    </PriceContext.Provider>
  );
};

export { Provider };
export default PriceContext;
