import { BallTriangle } from "react-loader-spinner";
import "./loader.css";

const Loader = () => {
  return (
    <BallTriangle
      radius={5}
      color="#001529"
      ariaLabel="ball-triangle-loading"
      wrapperClass="loader-wrapper"
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      visible={true}
    />
  );
};

export default Loader;
