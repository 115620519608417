import { useEffect, useState, createContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const CartContext = createContext({});

const Provider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [waitingOrder, setWaitingOrder] = useState({});
  const [isWaitingOrder, setIsWaitingOrder] = useState(false);
  const [count, setCount] = useState(0);
  const [detailsPanier, setDetailsPanier] = useState({
    optiques: 0,
    solaires: 0,
    accessoires: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    getCart();
    getCartCount();
  }, []);

  useEffect(() => {
    getTypeProducts();
    // console.log("detailsPanier: ", detailsPanier, count);
  }, [cart, count]);

  const getCart = () => {
    let virtualCart = [];

    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if (key.includes("mnb-sales-product")) {
          virtualCart.push(JSON.parse(localStorage.getItem(key)));
        }
      }
    }
    setCart(virtualCart);
  };

  const getCartCount = () => {
    let countTmp = 0;
    cart.forEach((produit) => {
      let qtyProduct = produit.qty;
      countTmp += qtyProduct;
    });
    setCount(countTmp);
  };

  const setProductQuantity = (qty, productById) => {
    return {
      product: productById,
      qty: qty,
    };
  };

  const addProductToCart = (product, qty) => {
    // console.log(product);
    setIsWaitingOrder(false);
    setWaitingOrder({});
    if (!localStorage.getItem(`mnb-sales-product ${product.Reference}`)) {
      localStorage.setItem(
        `mnb-sales-product ${product.Reference}`,
        JSON.stringify(setProductQuantity(qty, product))
      );
      // console.log("ajouté au panier");
      toast.success(t("Produit ajouté au panier"));
    } else {
      localStorage.setItem(
        `mnb-sales-product ${product.Reference}`,
        JSON.stringify(
          setProductQuantity(
            JSON.parse(
              localStorage.getItem(`mnb-sales-product ${product.Reference}`)
            ).qty + qty,
            product
          )
        )
      );
      // console.log("déjà dans le panier: quantité update");
      toast.success(t("Quantité mise à jour"));
    }
    getCart();
  };

  const addProductFromWaitingOrderToCart = (product, qty) => {
    // console.log(product);
    setIsWaitingOrder(true);
    if (!localStorage.getItem(`mnb-sales-product ${product.Reference}`)) {
      localStorage.setItem(
        `mnb-sales-product ${product.Reference}`,
        JSON.stringify(setProductQuantity(qty, product))
      );
      // console.log("ajouté au panier");
      toast.success(t("Produit ajouté au panier"));
    } else {
      localStorage.setItem(
        `mnb-sales-product ${product.Reference}`,
        JSON.stringify(
          setProductQuantity(
            JSON.parse(
              localStorage.getItem(`mnb-sales-product ${product.Reference}`)
            ).qty + qty,
            product
          )
        )
      );
      // console.log("déjà dans le panier: quantité update");
      toast.success(t("Quantité mise à jour"));
    }
    getCart();
  };

  const deleteCartItem = (id) => {
    // console.log(`delete product ${id}`);
    if (localStorage.getItem(`mnb-sales-product ${id}`)) {
      localStorage.removeItem(`mnb-sales-product ${id}`);
      toast.success(t("Produit supprimé du panier"));
      getCart();
    }
  };

  const clearCart = () => {
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if (key.includes("mnb-sales-product")) {
          localStorage.removeItem(key);
        }
      }
    }
    getCart();
  };

  const getTypeProducts = () => {
    const codeCouleurRegex = new RegExp("^[0-9]{3}$");
    let opt = 0;
    let sol = 0;
    let access = 0;

    cart?.forEach((product) => {
      const tabName = product.product?.Intitule.split(" ");
      tabName?.forEach((tab, index) => {
        const tabToInt = parseInt(tab);
        if (
          typeof tabToInt === "number" &&
          !isNaN(tabToInt) &&
          codeCouleurRegex.test(tabToInt)
        ) {
          if (index + 1 < tabName.length) {
            const type = tabName[index + 1];
            if (type.includes("SOL")) {
              sol += 1 * product.qty;
            }
            if (type.includes("OPT")) {
              opt += 1 * product.qty;
            }
          }
        }
      });
      if (product?.product?.CodeFamille === "FA0003") {
        access += 1 * product.qty;
      }
    });

    setDetailsPanier({
      optiques: opt,
      solaires: sol,
      accessoires: access,
    });
  };

  const addOrRemoveOne = (operator, product, cart) => {
    if (operator === "+") {
      let productToUpdate = cart.filter(
        (prod) => prod.product.Reference === product.product.Reference
      )[0];
      if (productToUpdate.qty < 30) {
        productToUpdate.qty++;
        localStorage.setItem(
          `mnb-sales-product ${productToUpdate.product.Reference}`,
          JSON.stringify(productToUpdate)
        );
      }
    }
    if (operator === "-") {
      let productToUpdate = cart.filter(
        (prod) => prod.product.Reference === product.product.Reference
      )[0];
      if (productToUpdate.qty > 0) {
        productToUpdate.qty--;
        localStorage.setItem(
          `mnb-sales-product ${productToUpdate.product.Reference}`,
          JSON.stringify(productToUpdate)
        );
      }
      if (productToUpdate.qty === 0) {
        deleteCartItem(productToUpdate.product.Reference);
      }
    }
    getCart();
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        getCart,
        count,
        getCartCount,
        deleteCartItem,
        clearCart,
        addProductToCart,
        addProductFromWaitingOrderToCart,
        detailsPanier,
        addOrRemoveOne,
        waitingOrder,
        setWaitingOrder,
        isWaitingOrder,
        setIsWaitingOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { Provider };
export default CartContext;
