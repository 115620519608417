import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import toast from "react-hot-toast";
import CartContext from "../../contexts/CartContext";

const ServiceWorkerHandler = () => {
  const { clearCart } = useContext(CartContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showUpdateModal = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data.command === "commandSent") {
        toast.success(event.data.message);
      } else if (event.data.command === "commandStored") {
        toast.success(event.data.message);
        clearCart();
      } else if (event.data.command === "REFRESH_PAGE") {
        console.log("refresh the client page");
        showUpdateModal();
      }
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleServiceWorkerMessage
    );

    // Nettoyer l'écouteur d'événement lorsque le composant est démonté
    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleServiceWorkerMessage
      );
    };
  }, []);

  return (
    <Modal
      title="Mise à jour disponible"
      open={isModalOpen}
      onOk={handleUpdate}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Non, merci
        </Button>,
        <Button onClick={handleUpdate}>Rafraichir</Button>,
      ]}
    >
      <p>Une mise à jour est disponible, voulez-vous rafraichir la page ?</p>
    </Modal>
  );
};

export default ServiceWorkerHandler;
