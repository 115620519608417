import { Card, Col, Button } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";
import { ModalVolume } from "../modal-client-volume";

const DashboardClientVolume = ({ statusClient, totalVolumeByClient, isLoadingVolumeClient }) => {
  const [isModalReliquatOpen, setIsModalReliquatOpen] = useState(false);
  const [couleurStatus, setCouleurStatus] = useState(null);
  const { viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (statusClient !== "") {
      if (statusClient === "FERME") setCouleurStatus("#c0392b");
      if (statusClient === "STANDARD") setCouleurStatus("#cd7f32");
      if (statusClient === "PREMIUM") setCouleurStatus("#bdc3c7");
      if (statusClient === "VIP") setCouleurStatus("#ffd700");
      if (statusClient === "CLUB MNB") setCouleurStatus("#000");
    }
  }, [statusClient]);

  const showModalVolume = () => {
    setIsModalReliquatOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalReliquatOpen(false);
  };

  return (
    <Fragment>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card title={`${t("VOLUME")} ${new Date().getFullYear()}`} style={{ height: "100%" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {statusClient && couleurStatus ? (
              <div
                style={{
                  position: "absolute",
                  top: "60px",
                  right: "0px",
                  padding: "8px 16px",
                  color: "#fff",
                  fontWeight: "bold",
                  backgroundColor: couleurStatus,
                }}>
                {statusClient}
              </div>
            ) : (
              ""
            )}
            {isLoadingVolumeClient ? (
              <Loader />
            ) : totalVolumeByClient && totalVolumeByClient.totalQty > 0 ? (
              <>
                <p
                  style={
                    viewportWidth < 1181
                      ? {
                          fontSize: "1.6rem",
                          margin: 0,
                        }
                      : {
                          fontSize: "2rem",
                          margin: 0,
                        }
                  }>
                  {totalVolumeByClient.totalQty}{" "}
                  {totalVolumeByClient.totalQty === 1 ? "pièce" : "pièces"}
                </p>
                {totalVolumeByClient && totalVolumeByClient.products.length ? (
                  <Button type="ghost" onClick={showModalVolume}>
                    {t("Plus d'infos")}
                  </Button>
                ) : (
                  ""
                )}
              </>
            ) : (
              <p
                style={{
                  fontSize: "2rem",
                  margin: 0,
                }}>
                0 pièce
              </p>
            )}
          </div>
          <ModalVolume
            articlesVolume={totalVolumeByClient?.products}
            open={isModalReliquatOpen}
            onOk={handleCloseModal}
          />
        </Card>
      </Col>
    </Fragment>
  );
};

export default DashboardClientVolume;
