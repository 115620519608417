import { useContext, useEffect, useRef, useState } from "react";
import { Space, Table, Button, Input, Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Loader } from "../../components";
import MainContext from "../../contexts/MainContext";
import AuthContext from "../../contexts/AuthContext";
import SearchContext from "../../contexts/SearchContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ClientsPage = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { t } = useTranslation();
  const {
    isLoadingClients,
    clients,
    getClientsByRepresentantId,
    getInfosAllRepresentants,
    representants,
    viewportWidth,
    viewportHeight,
  } = useContext(MainContext);
  const { user, isLoadingAccessToken } = useContext(AuthContext);
  const { filters, saveFilters } = useContext(SearchContext);

  const tableId = "clientsTable";
  const navigate = useNavigate();

  useEffect(() => {
    getInfosAllRepresentants();

    // console.log(clients);
    if (!clients.length) {
      if (user && user.role !== "guest") {
        if (!isLoadingAccessToken) {
          // console.log("user: ", user);
          getClientsByRepresentantId(
            user?.role === "admin" ? "all" : user?.repId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    const tableFilters = filters[tableId] || {};
    if (tableFilters.searchText && tableFilters.searchedColumn) {
      setSearchText(tableFilters.searchText);
      setSearchedColumn(tableFilters.searchedColumn);
    }
  }, [filters, tableId]);

  const getRepresentant = (repId, user) => {
    let representant;

    if (user !== null) {
      if (repId === user.repId && user.repId !== -1) {
        return `${user.firstname} ${user.lastname}`;
      } else {
        representants?.forEach((rep) => {
          if (rep.Id === repId) {
            representant = rep;
          }
        });
      }
      return representant ? `${representant.Prenom} ${representant.Nom}` : "";
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    saveFilters(tableId, {
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText([]);
    setSearchedColumn([]);
    saveFilters(tableId, { searchText: [], searchedColumn: [] });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("Rechercher par")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 110,
            }}
          >
            {t("Rechercher")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm();
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter:
      dataIndex === "CodePostal"
        ? (value, record) =>
            record[dataIndex]?.toString()?.substring(0, 2) ===
            value.toString().substring(0, 2)
        : (value, record) => {
            return record[dataIndex]
              ?.toString()
              ?.toLowerCase()
              ?.includes(value?.toLowerCase());
          },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns =
    user?.role === "admin"
      ? [
          {
            title: t("Intitule"),
            dataIndex: "Intitule",
            key: "Intitule",
            filteredValue:
              filters[tableId] && filters[tableId].searchedColumn === "Intitule"
                ? [filters[tableId].searchText]
                : [],
            width: viewportWidth < 1090 ? 200 : "",
            ...getColumnSearchProps("Intitule"),
            sorter: (a, b) =>
              a.Intitule < b.Intitule ? -1 : a.Intitule > b.Intitule ? 1 : 0,
          },
          {
            title: "Id",
            dataIndex: "NumeroTiers",
            key: "NumeroTiers",
            filteredValue:
              filters[tableId] &&
              filters[tableId].searchedColumn === "NumeroTiers"
                ? [filters[tableId].searchText]
                : [],
            width: viewportWidth < 1090 ? 100 : "",
            align: "center",
            // responsive: ["md"],
            ...getColumnSearchProps("NumeroTiers"),
          },
          {
            title: t("Ville"),
            dataIndex: "Ville",
            key: "Ville",
            filteredValue:
              filters[tableId] && filters[tableId].searchedColumn === "Ville"
                ? [filters[tableId].searchText]
                : [],
            width: 112,
            align: "center",
            ...getColumnSearchProps("Ville"),
          },
          {
            title: t("Téléphone"),
            dataIndex: "Telephone",
            key: "Telephone",
            width: 160,
            align: "center",
            responsive: ["md"],
          },
          {
            title: t("Code postal"),
            dataIndex: "CodePostal",
            key: "CodePostal",
            filteredValue:
              filters[tableId] &&
              filters[tableId].searchedColumn === "CodePostal"
                ? [filters[tableId].searchText]
                : [],
            width: 112,
            align: "center",
            ...getColumnSearchProps("CodePostal"),
          },
          {
            title: viewportWidth < 1070 ? "Rep, Id" : t("Représentant, Id"),
            key: "action",
            filteredValue:
              filters[tableId] &&
              filters[tableId].searchedColumn === "IdRepresentant"
                ? [filters[tableId].searchText]
                : [],
            width: viewportWidth < 915 ? 100 : 176,
            align: "center",
            ...getColumnSearchProps("IdRepresentant"),
            sorter: (a, b) =>
              a.IdRepresentant < b.IdRepresentant
                ? -1
                : a.IdRepresentant > b.IdRepresentant
                ? 1
                : 0,
            render: (client) => {
              return (
                getRepresentant(client.IdRepresentant, user) +
                ", " +
                client?.IdRepresentant
              );
            },
          },
        ]
      : [
          {
            title: t("Intitule"),
            dataIndex: "Intitule",
            key: "Intitule",
            filteredValue:
              filters[tableId] && filters[tableId].searchedColumn === "Intitule"
                ? [filters[tableId].searchText]
                : [],
            width: viewportWidth < 1090 ? 200 : "",
            ...getColumnSearchProps("Intitule"),
            sorter: (a, b) =>
              a.Intitule < b.Intitule ? -1 : a.Intitule > b.Intitule ? 1 : 0,
          },
          {
            title: "Id",
            dataIndex: "NumeroTiers",
            key: "NumeroTiers",
            filteredValue:
              filters[tableId] &&
              filters[tableId].searchedColumn === "NumeroTiers"
                ? [filters[tableId].searchText]
                : [],
            width: viewportWidth < 1090 ? 100 : "",
            align: "center",
            ...getColumnSearchProps("NumeroTiers"),
          },
          {
            title: t("Ville"),
            dataIndex: "Ville",
            key: "Ville",
            filteredValue:
              filters[tableId] && filters[tableId].searchedColumn === "Ville"
                ? [filters[tableId].searchText]
                : [],
            width: 112,
            align: "center",
            ...getColumnSearchProps("Ville"),
          },
          {
            title: t("Téléphone"),
            dataIndex: "Telephone",
            key: "Telephone",
            filteredValue:
              filters[tableId] &&
              filters[tableId].searchedColumn === "Telephone"
                ? [filters[tableId].searchText]
                : [],
            width: 160,
            align: "center",
            responsive: ["md"],
          },
          {
            title: t("Code postal"),
            dataIndex: "CodePostal",
            key: "CodePostal",
            filteredValue:
              filters[tableId] &&
              filters[tableId].searchedColumn === "CodePostal"
                ? [filters[tableId].searchText]
                : [],
            width: 112,
            align: "center",
            ...getColumnSearchProps("CodePostal"),
          },
        ];

  return isLoadingClients ? (
    <Loader />
  ) : clients.length ? (
    <Table
      loading={isLoadingClients}
      rowKey={(client) => client.NumeroTiers}
      scroll={{
        y: viewportHeight < 1080 ? "74vh" : "80vh",
      }}
      pagination={{ size: "small", position: ["bottomCenter"] }}
      columns={columns}
      dataSource={clients}
      onRow={(client) => {
        return {
          onClick: () => {
            // console.log("click ", client);
            navigate(`${client.NumeroTiers}`);
          },
        };
      }}
    />
  ) : (
    <Empty description={<span>{t("Aucun client")}</span>} />
  );
};

export default ClientsPage;
