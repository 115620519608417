import { createContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  collection,
  query,
  where,
  initializeFirestore,
  persistentLocalCache,
  onSnapshot,
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  onIdTokenChanged,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "mnb-sales-auth.firebaseapp.com",
  projectId: "mnb-sales-auth",
  storageBucket: "mnb-sales-auth.appspot.com",
  messagingSenderId: "356153588080",
  appId: "1:356153588080:web:ee6d13419d16f5de2037b5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

const db = initializeFirestore(app, { localCache: persistentLocalCache({}) });
// console.log(db);

const AuthContext = createContext({});

const Provider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState(null);
  const [accessTokenJwt, setAccessTokenJwt] = useState(null);
  const [isLoadingAccessToken, setIsLoadingAccessToken] = useState(true);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasShownToast, setHasShownToast] = useState(false);

  // useEffect(() => {
  //   console.log("accessToken authContext: ", accessTokenJwt);
  //   console.log(isLoadingAccessToken);
  //   console.log("user: ", user);
  // }, [accessTokenJwt, user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // console.log("user on auth state changed", currentUser);
        getInfosUser(currentUser.uid);
      } else {
        setIsConnected(false);
      }
    });

    // Nettoyez l'écouteur d'authentification au démontage du composant
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && !hasShownToast) {
      toast.success(`Bienvenue ${user.firstname}`);
      setHasShownToast(true);
    }
  }, [user]);

  const getInfosUser = async (userUid) => {
    const q = query(collection(db, "users"), where("userId", "==", userUid));

    const unsubscribe = onSnapshot(
      q,
      { includeMetadataChanges: true },
      (snapshot) => {
        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          const userData = doc.data();
          setUser(userData);

          const source = snapshot.metadata.fromCache ? "local cache" : "server";
          console.log("Data came from " + source);
        } else {
          console.log("L'utilisateur n'existe pas");
        }
      }
    );

    // Retournez la fonction de nettoyage pour vous désabonner de l'écouteur lorsque le composant est démonté
    return () => unsubscribe();
  };

  const onSignOut = () => {
    signOut(auth)
      .then(() => {
        setIsConnected(false);
        setUserId(null);
        setAccessTokenJwt(null);
        setUser(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onIdTokenChanged(auth, async (currentUser) => {
    if (currentUser) {
      setIsConnected(true);
      setIsLoading(false);
      setUserId(currentUser.uid);
      // if (!accessTokenJwt) {
      //   auth.currentUser.getIdToken(true).then((idToken) => {
      //     const accessToken = idToken;
      //     setAccessTokenJwt(accessToken);
      //     setIsLoadingAccessToken(false);
      //   });
      // }
      const accessToken = await currentUser.getIdToken();
      setAccessTokenJwt(accessToken);
      setIsLoadingAccessToken(false);
    } else {
      setIsConnected(false);
      setUserId(null);
      setAccessTokenJwt(null);
      setUser(null);
    }
  });

  const checkUserConnexion = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUserId(userCredential.user.uid);
        setIsConnected(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        checkUserConnexion,
        isConnected,
        userId,
        accessTokenJwt,
        isLoadingAccessToken,
        user,
        getInfosUser,
        onSignOut,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { Provider };
export default AuthContext;
