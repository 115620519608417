import { Button, Card, Col, Divider, Row } from "antd";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { ModalImpayes } from "../modal-client-impayes";
import MainContext from "../../contexts/MainContext";

const DashboardClientImpaye = ({
  montantImpayeN,
  tabFacturesImpayesN,
  montantImpayeNMoinsUn,
  tabFacturesImpayesNMoinsUn,
}) => {
  const { t } = useTranslation();
  const [isModalImpayesOpen, setIsModalImpayesOpen] = useState(false);
  const [year, setYear] = useState("n");
  const totalMontantImpayeN = Number(montantImpayeN);
  const totalMontantImpayeNMoinsUn = Number(montantImpayeNMoinsUn);

  const { viewportWidth } = useContext(MainContext);

  const showModalImpayesN = () => {
    setIsModalImpayesOpen(true);
    setYear("n");
  };

  const showModalImpayesNMoinsUn = () => {
    setIsModalImpayesOpen(true);
    setYear("n-1");
  };

  const handleCloseModal = () => {
    setIsModalImpayesOpen(false);
  };

  return (
    <Card
      title={t("ARRIVÉES À ÉCHÉANCE")}
      style={{ height: "100%" }}
      bodyStyle={
        viewportWidth < 1181
          ? {
              padding: "16px 16px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }
          : {
              padding: "16px 16px",
              fontSize: "1.6rem",
              fontWeight: "bold",
            }
      }>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <p
              style={{
                textAlign: "left",
                margin: 0,
                color: "#1890ff",
              }}>
              {new Date().getFullYear()} :{" "}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            {!totalMontantImpayeN === null ? (
              <Loader />
            ) : totalMontantImpayeN && totalMontantImpayeN > 0 ? (
              <p
                style={{
                  textAlign: "right",
                  margin: 0,
                  color: "red",
                }}>
                {totalMontantImpayeN.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                &nbsp;€
              </p>
            ) : (
              <p
                style={{
                  textAlign: "right",
                  margin: 0,
                  color: "green",
                }}>
                {totalMontantImpayeN.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                &nbsp;€
              </p>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            {tabFacturesImpayesN && tabFacturesImpayesN.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "8px",
                }}>
                <Button type="ghost" onClick={showModalImpayesN}>
                  Détails
                </Button>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Divider style={{ margin: 8 }} />
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <p
              style={{
                textAlign: "left",
                margin: 0,
                color: "#1890ff",
              }}>
              {new Date().getFullYear() - 1}:{" "}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            {!totalMontantImpayeNMoinsUn === null ? (
              <Loader />
            ) : totalMontantImpayeNMoinsUn && totalMontantImpayeNMoinsUn > 0 ? (
              <p
                style={{
                  textAlign: "right",
                  margin: 0,
                  color: "red",
                }}>
                {totalMontantImpayeNMoinsUn.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                &nbsp;€
              </p>
            ) : (
              <p
                style={{
                  textAlign: "right",
                  margin: 0,
                  color: "green",
                }}>
                {totalMontantImpayeNMoinsUn.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                &nbsp;€
              </p>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            {tabFacturesImpayesNMoinsUn && tabFacturesImpayesNMoinsUn.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "8px",
                }}>
                <Button type="ghost" onClick={showModalImpayesNMoinsUn}>
                  Détails
                </Button>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <ModalImpayes
        tabFacturesImpayesN={tabFacturesImpayesN}
        tabFacturesImpayesNMoinsUn={tabFacturesImpayesNMoinsUn}
        open={isModalImpayesOpen}
        onOk={handleCloseModal}
        year={year}
      />
    </Card>
  );
};

export default DashboardClientImpaye;
