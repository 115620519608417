import "./App.css";
import { changeCountryCodeToFlag } from "./utils/functions";
import {
  AppstoreOutlined,
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
  LogoutOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { LoginPage } from "./containers";
import AuthContext from "./contexts/AuthContext";
import CartContext from "./contexts/CartContext";
import { Cart, LanguageSwitcher, ServiceWorkerHandler } from "./components";
import MainContext from "./contexts/MainContext";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import PriceContext from "./contexts/PriceContext";

const { Header, Content, Footer, Sider } = Layout;

function App() {
  const { isConnected, user, onSignOut } = useContext(AuthContext);
  const { cart } = useContext(CartContext);
  const { viewportWidth, current, setCurrent } = useContext(MainContext);
  const { rolesUser, setActiveRole } = useContext(PriceContext);
  const [collapsible, setCollapsible] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [openSiderWidth, setOpenSiderWidth] = useState(200);
  const [marginLeftContent, setMarginLeftContent] = useState(200);
  const [currentRoute, setCurrentRoute] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const currentRoutetmp = location.pathname.split("/");
    const currentRoute = `/${currentRoutetmp[1]}`;
    setCurrentRoute(currentRoute);
  }, [location]);

  useEffect(() => {
    viewportWidth < 750 ? setCollapsible(false) : setCollapsible(true);
    // console.log(viewportWidth);
  }, [viewportWidth]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(t("Produits"), "produits", <AppstoreOutlined />, [
      getItem(t("Tous les produits"), "/produits/famille/all"),
      "group",
      // getItem(t("Disponibles"), "/produits/famille/dispo"),
      // "group",
      // getItem("Nathalie Blanc Paris", "/produits/famille/NB"),
      // "group",
      // getItem("Monsieur Blanc", "/produits/famille/MB"),
      // "group",
      // getItem("Blanc", "/produits/famille/FA0006"),
      // "group",
      // getItem(t("Accessoires"), "/produits/famille/FA0003"),
      // "group",
      // getItem("Bäumer", "/produits/famille/BAU"),
      // "group",
      // getItem("Shelter", "/produits/famille/SH"),
      // "group",
      // getItem("Ouchenir", "/produits/famille/OUCH"),
      // "group",
      // getItem("Asian Fitting", "/produits/famille/AF"),
      // "group",
      // getItem(t("PLV"), "/produits/famille/PLV"),
      // "group",
    ]),
    user?.role !== "guest"
      ? getItem("Clients", "clients", <TeamOutlined />, [
          getItem(t("Tous les clients"), "/clients"),
          "group",
          getItem(t("Créer"), "/client/create"),
          "group",
        ])
      : "",
    user?.role !== "guest"
      ? getItem(t("Historique"), "historique", <FileTextOutlined />, [
          getItem(t("Bons de commande"), "/historique/bons-de-commande"),
          "group",
          getItem(t("Prepa. de Livraison"), "/historique/prepa-de-livraison"),
          "group",
          getItem(t("Bons de Livraison"), "/historique/bons-de-livraison"),
          "group",
          getItem(t("Factures"), "/historique/factures"),
          "group",
          getItem(t("Factures Comptabilisées"), "/historique/factures-comptabilisees"),
          "group",
        ])
      : "",
    user?.role !== "guest" ? getItem(t("Downloads"), "downloads", <DownloadOutlined />) : "",
    user?.role !== "guest"
      ? getItem(t("Waitlist orders"), `/waitlist-orders/${user?.repId}`, <ReloadOutlined />)
      : "",
  ];

  const onClick = (e) => {
    // console.log("click ", e);
    setCurrent(e.key);
    navigate(e.key);
  };

  const onSignOutClick = () => {
    // console.log("sign out !");
    onSignOut();
  };

  const onUserLogoClick = () => {
    navigate(`/representant/${user?.repId}`);
    setCurrent("");
    toast.success(`Connecté en tant que ${user?.firstname} ${user?.lastname}`);
  };

  return isConnected ? (
    <Layout
      style={{
        minHeight: "100vh",
      }}>
      <ServiceWorkerHandler />
      {/* Messages toast */}
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            duration: 5000,
          },
          error: {
            duration: 10000,
          },
        }}
      />
      <Sider
        breakpoint="md"
        width={openSiderWidth}
        collapsedWidth={collapsedWidth}
        collapsible={collapsible}
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
          collapsed ? setMarginLeftContent(openSiderWidth) : setMarginLeftContent(collapsedWidth);
        }}
        onBreakpoint={(broken) => {
          broken ? setCollapsedWidth(60) : setCollapsedWidth(80);
          broken ? setOpenSiderWidth(120) : setOpenSiderWidth(200);
        }}
        style={{
          paddingTop: 60,
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          overflow: "auto",
        }}>
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          selectedKeys={[current]}
          onClick={onClick}
          className="menu-side-bar"
        />
        {/* <CartDrawer /> */}
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            position: "fixed",
            top: 0,
            zIndex: 2,
          }}>
          <ul
            className="navbar-list"
            style={{
              paddingLeft: viewportWidth > 640 ? (collapsed ? collapsedWidth : openSiderWidth) : 0,
            }}>
            {/* <li style={{ width: "140px" }}></li> */}
            <li /*style={{ width: "200px" }}*/>
              <div className="navbar-picto-wrapper">
                <div className="navbar-picto">
                  <LanguageSwitcher />
                </div>
              </div>
            </li>

            {viewportWidth > 820 ? (
              <li style={{ width: "200px", flex: "1" }}>
                <div className="logo">
                  <img
                    className="logo-mnb-home"
                    src={`${process.env.PUBLIC_URL}/logo-MNB-blanc.png`}
                    alt="logo maison nathalie blanc"
                  />
                </div>
              </li>
            ) : (
              ""
            )}
            {user?.role.includes(",") ? (
              <li>
                <Select
                  options={rolesUser.map((role) => ({
                    value: role,
                    label: changeCountryCodeToFlag(role),
                  }))}
                  style={{
                    // width: viewportWidth > 800 ? 140 : "",
                    color: "#fff",
                  }}
                  bordered={false}
                  defaultValue={user?.role.split(",")[0]}
                  onChange={(value) => {
                    setActiveRole(value);
                  }}></Select>
              </li>
            ) : (
              ""
            )}

            <li /*style={{ width: "200px" }}*/>
              <div className="navbar-picto-wrapper">
                {/* <div className="navbar-picto">
                  <LanguageSwitcher />
                </div> */}
                <div className="navbar-picto">
                  <UserOutlined onClick={() => onUserLogoClick()} />
                </div>
                <div className="navbar-picto" onClick={onSignOutClick}>
                  <LogoutOutlined />
                </div>
              </div>
            </li>
          </ul>
        </Header>
        <Content className="main-content-wrapper" style={{ maxHeight: "100%" }}>
          <div
            className="main-content"
            style={{
              marginTop: 64,
              marginLeft: viewportWidth < 750 ? "60px" : marginLeftContent,
              padding: 24,
              minHeight: 360,
            }}>
            <Outlet />
          </div>
        </Content>
        {cart.length && currentRoute !== "/cart" && user?.role !== "guest" ? (
          <Cart marginLeftContent={viewportWidth < 750 ? 60 : marginLeftContent} />
        ) : (
          <Footer
            style={{
              textAlign: "center",
              marginLeft: viewportWidth < 750 ? 60 : marginLeftContent,
            }}>
            Maison Nathalie Blanc ©{Date().split(" ")[3]}
          </Footer>
        )}
      </Layout>
    </Layout>
  ) : (
    <LoginPage />
  );
}

export default App;
