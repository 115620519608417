import { useNavigate } from "react-router-dom";
import "./boutons.css";
import { useTranslation } from "react-i18next";

const GoToCart = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const clickGoToCartBtn = () => {
		navigate("/cart");
	};

	return (
		<button className="go-to-cart-btn" onClick={() => clickGoToCartBtn()}>
			{t("Panier")}
		</button>
	);
};

export default GoToCart;
