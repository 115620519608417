import { ShoppingOutlined } from "@ant-design/icons";
import { useContext } from "react";
import CartContext from "../../contexts/CartContext";
import { useTranslation } from "react-i18next";

const addToCartButtonEnabled = {
  backgroundColor: "#6ab04c",
  width: "100%",
  padding: "12px",
  border: "none",
  color: "white",
  fontSize: "1.4rem",
  fontWeight: "bold",
  cursor: "pointer",
};

const addToCartButtonDisabled = {
  backgroundColor: "#dfe6e9",
  width: "100%",
  padding: "12px",
  border: "none",
  color: "white",
  fontSize: "1.4rem",
  fontWeight: "bold",
  cursor: "not-allowed",
};

const AddToCart = ({ productById, inputValue, stockProduct }) => {
  const { getCart, addProductToCart } = useContext(CartContext);

  const { t } = useTranslation();

  const clickAddToCartBtn = (productById) => {
    addProductToCart(productById, inputValue, stockProduct);
    getCart();
    window.history.back();
  };

  return (
    <button
      onClick={() => clickAddToCartBtn(productById)}
      disabled={!productById.Publie ? (stockProduct > 0 ? false : true) : false}
      style={
        !productById.Publie
          ? stockProduct > 0
            ? addToCartButtonEnabled
            : addToCartButtonDisabled
          : addToCartButtonEnabled
      }
    >
      {t("Ajouter au panier")} <ShoppingOutlined />
    </button>
  );
};

export default AddToCart;
