import { createContext, useContext, useEffect, useLayoutEffect, useState } from "react";
import AuthContext from "./AuthContext";

const MainContext = createContext({});

const Provider = ({ children }) => {
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isLoadingCA, setIsLoadingCA] = useState(false);
  const [isLoadingBonsCommande, setIsLoadingBonsCommande] = useState(false);
  const [isLoadingReliquat, setIsLoadingReliquat] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [productById, setProductById] = useState({});
  const [clients, setClients] = useState([]);
  const [clientById, setClientById] = useState(null);
  const [stockProduct, setStockProduct] = useState(0);
  const [representants, setRepresentants] = useState([]);
  const [usRepresentants, setUSRepresentants] = useState([]);
  const [factures, setFactures] = useState([]);
  const [bonsCommande, setBonsCommande] = useState([]);
  const [totalVolumeByClient, setTotalVolumeByClient] = useState(null);
  const [isLoadingVolumeClient, setIsLoadingVolumeClient] = useState(true);
  const [viewportWidth, setViewportWidth] = useState(document.body.clientWidth);
  const [viewportHeight, setViewportHeight] = useState(document.body.clientHeight);
  const [current, setCurrent] = useState("");
  const [caByRepresentant, setCaByRepresentant] = useState({});
  const [volumeByRepresentant, setVolumeByRepresentant] = useState({});
  const [newClientsByRepresentant, setNewClientsByRepresentant] = useState(0);
  const [topAndFlopClientsByRepresentant, setTopAndFlopClientsByRepresentant] = useState({});
  const [totalReliquatByRepresentant, setTotalReliquatByRepresentant] = useState([]);
  const [totalFacturesImpayesByRepresentant, setTotalFacturesImpayesByRepresentant] = useState({});
  const [allArticlesByClientByBonDeCommande, setAllArticlesByClientByBonDeCommande] = useState([]);
  const [allArticlesByClientByFacture, setAllArticlesByClientByFacture] = useState([]);
  const [isLoadingBestSeller, setIsLoadingBestSeller] = useState(true);
  const [bonsDeCommandeAgent, setBonsDeCommandeAgent] = useState([]);
  const [preparationsDeLivraisonAgent, setPreparationsDeLivraisonAgent] = useState([]);
  const [bonsDeLivraisonAgent, setBonsDeLivraisonAgent] = useState([]);
  const [facturesAgent, setFacturesAgent] = useState([]);
  const [facturesComptabiliseesAgent, setFacturesComptabiliseesAgent] = useState([]);
  const [totalFacturesImpayesByClientN, setTotalFacturesImpayesByClientN] = useState({});
  const [totalFacturesImpayesByClientNMoinsUn, setTotalFacturesImpayesByClientNMoinsUn] = useState(
    {}
  );
  const [totalDuClient, setTotalDuClient] = useState({});
  const [futuresLivraisons, setFuturesLivraisons] = useState([]);

  const { accessTokenJwt, isLoadingAccessToken, user } = useContext(AuthContext);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setViewportWidth(document.body.clientWidth);
      setViewportHeight(document.body.clientHeight);
    });
  }, [viewportWidth, viewportHeight]);

  useEffect(() => {
    if (user && user.role !== "guest") {
      if (navigator.onLine) {
        if (accessTokenJwt) {
          if (user?.repId === 107 || user?.repId === 105) {
            getUSRep();
          }
          getClientsByRepresentantId(user?.role === "admin" ? "all" : user?.repId);
          getProducts();
          getFuturesLivraisons();
          getCAByRepresentant(user?.repId);
          getTotalReliquatByRepresentant(user?.repId);
          getTotalFacturesImpayesByAgent(user?.repId);
          getNewClientsByRepresentant(user?.repId);
          getTopAndFlopClientsByRepresentant(user?.repId);
          getDocumentsByRepresentant(user?.repId, 1);
          getDocumentsByRepresentant(user?.repId, 2);
          getDocumentsByRepresentant(user?.repId, 3);
          getDocumentsByRepresentant(user?.repId, 6);
          getDocumentsByRepresentant(user?.repId, 7);
        }
      } else {
        if (user?.repId === 107 || user?.repId === 105) {
          getUSRep();
        }
        getClientsByRepresentantId(user?.role === "admin" ? "all" : user?.repId);
        getProducts();
        getFuturesLivraisons();
        getCAByRepresentant(user?.repId);
        getTotalReliquatByRepresentant(user?.repId);
        getTotalFacturesImpayesByAgent(user?.repId);
        getNewClientsByRepresentant(user?.repId);
        getTopAndFlopClientsByRepresentant(user?.repId);
        getDocumentsByRepresentant(user?.repId, 1);
        getDocumentsByRepresentant(user?.repId, 2);
        getDocumentsByRepresentant(user?.repId, 3);
        getDocumentsByRepresentant(user?.repId, 6);
        getDocumentsByRepresentant(user?.repId, 7);
      }
    }
    if (user && user?.role === "guest") {
      getProducts();
    }
  }, [user]);

  const getProducts = async () => {
    setIsLoadingProducts(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setAllProducts(data);
    } catch (error) {
      console.log("erreur getProducts: ", error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const getProductById = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/ref/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenJwt}`,
      },
    });
    const data = await response.json();
    //console.log("product by id: ", data);

    setProductById(data);
    setIsLoadingProducts(false);
  };

  const getStockProductById = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/stock/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenJwt}`,
      },
    });
    const data = await response.json();
    //console.log("stock product by id: ", data);
    const stock = data.QteStock - data.QteReserve;

    setStockProduct(stock);
  };

  const getClientById = async (clientId, retryCount = 0) => {
    setIsLoadingClient(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (response.url.includes("/offline.html")) {
        setClientById("offline");
        return;
      }

      const data = await response.json();
      setClientById(data);
    } catch (error) {
      if (retryCount < 1) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getClientById(clientId, retryCount + 1), 2000);
      }
    } finally {
      setIsLoadingClient(false);
    }
  };

  const getClientsByRepresentantId = async (repId, retryCount = 0) => {
    setIsLoadingClients(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clients/rep/${await repId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setClients(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getClientsByRepresentantId(repId, retryCount + 1), 2000); // Attente de 2 secondes avant de réessayer
      }
    } finally {
      setIsLoadingClients(false);
    }
  };

  const getInfosAllRepresentants = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/collaborateurs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenJwt}`,
      },
    });

    const data = await response.json();

    // console.log("infos all rep: ", data);
    setRepresentants(data);
  };

  const getUSRep = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/collaborateurs-usa`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenJwt}`,
      },
    });

    const data = await response.json();

    setUSRepresentants(data);
  };

  const getFacturesByClient = async (clientId) => {
    setIsLoadingCA(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/factures/client/${clientId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      //console.log("factures by client: ", data);
      setFactures(data);
      setIsLoadingCA(false);
    }
  };

  const getBonsCommandeByClient = async (clientId) => {
    setIsLoadingBonsCommande(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/bons-commande/client/${clientId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );
      // console.log("response getBonsCommandeByClient: ", response);
      const data = await response.json();

      //console.log("bons commande by client: ", data);
      setBonsCommande(data);
      setIsLoadingBonsCommande(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getArticlesByDoc = async (docType, docId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/type/${docType}/lignes/${docId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      }
    );

    if (response.url.includes("/offline.html")) {
      return "offline";
    }

    const data = await response.json();

    if (response.status === 200) {
      return await data;
    }
  };

  // const getArticlesByBonCommande = async (commandeId) => {
  //   // setIsLoadingReliquat(true);
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_URL}/documents/bons-commande/lignes/${commandeId}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessTokenJwt}`,
  //       },
  //     }
  //   );
  //   const data = await response.json();

  //   // setIsLoadingReliquat(false);
  //   if (response.status === 200) {
  //     return await data;
  //   }
  // };

  const getArticlesByFacture = async (factureId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/factures/lignes/${factureId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      }
    );
    const data = await response.json();

    //console.log("article by facture: ", data);
    return await data;
  };

  /** Fonction qui gère les reliquats et les best seller des clients (Bons de commande = Reliquat | Facture = Best seller)  */
  const getAllArticlesByDocTypeByClient = async (clientId, docType) => {
    setIsLoadingReliquat(true);
    setIsLoadingBestSeller(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/client/${clientId}/type/${docType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      // console.log("DATA", data);

      if (docType === 1) {
        setAllArticlesByClientByBonDeCommande(data);
        setIsLoadingReliquat(false);
        // console.log("DATA FACTURE: ", data);
      }

      if (docType === 7) {
        setAllArticlesByClientByFacture(data);
        setIsLoadingBestSeller(false);
        // console.log("DATA BDC: ", data);
      }
    }
  };

  const getVolumeByClient = async (clientId, retryCount = 0) => {
    setIsLoadingVolumeClient(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/client/${clientId}/volume`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTotalVolumeByClient(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getVolumeByClient(clientId, retryCount + 1), 2000);
      }
    } finally {
      setIsLoadingVolumeClient(false);
    }
  };

  const getCAByRepresentant = async (repId, retryCount = 0) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/representant/${repId}/ca`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setCaByRepresentant(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getCAByRepresentant(repId, retryCount + 1), 2000);
      }
    }
  };

  /** Inutilisé (trop lourd) */
  const getVolumeByRepresentant = async (repId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/representant/${repId}/volume`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenJwt}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    setVolumeByRepresentant(data);
  };

  const getNewClientsByRepresentant = async (repId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/representant/${repId}/new-clients`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setNewClientsByRepresentant(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getNewClientsByRepresentant(repId, retryCount + 1), 2000);
      }
    }
  };

  const getTopAndFlopClientsByRepresentant = async (repId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/representant/${repId}/top-clients`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTopAndFlopClientsByRepresentant(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getTopAndFlopClientsByRepresentant(repId, retryCount + 1), 2000);
      }
    }
  };

  const getTotalReliquatByRepresentant = async (repId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/representant/${repId}/total-reliquat`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTotalReliquatByRepresentant(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getTotalReliquatByRepresentant(repId, retryCount + 1), 2000);
      }
    }
  };

  const getTotalFacturesImpayesByAgent = async (repId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/representant/${repId}/impaye`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTotalFacturesImpayesByRepresentant(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getTotalFacturesImpayesByAgent(repId, retryCount + 1), 2000);
      }
    }
  };

  const getTotalFacturesImpayesByClientN = async (clientId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clients/${clientId}/impayes/n`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTotalFacturesImpayesByClientN(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getTotalFacturesImpayesByClientN(clientId, retryCount + 1), 2000);
      }
    }
  };

  const getTotalFacturesImpayesByClientNMoinsUn = async (clientId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clients/${clientId}/impayes/n-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTotalFacturesImpayesByClientNMoinsUn(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getTotalFacturesImpayesByClientNMoinsUn(clientId, retryCount + 1), 2000);
      }
    }
  };

  const getTotalDuByClient = async (clientId, retryCount = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clients/${clientId}/total-du`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      setTotalDuClient(data);
    } catch (error) {
      if (retryCount < 1 && navigator.onLine) {
        console.log(`Retrying... (${retryCount + 1})`);
        setTimeout(() => getTotalDuByClient(clientId, retryCount + 1), 2000);
      }
    }
  };

  const getDocumentsByRepresentant = async (repId, docType) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/rep/${repId}/type/${docType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessTokenJwt}`,
          },
        }
      );

      if (accessTokenJwt !== null && response.status === 200) {
        const data = await response.json();
        if (docType === 1) setBonsDeCommandeAgent(data.reverse());
        if (docType === 2) setPreparationsDeLivraisonAgent(data.reverse());
        if (docType === 3) setBonsDeLivraisonAgent(data.reverse());
        if (docType === 6) setFacturesAgent(data.reverse());
        if (docType === 7) setFacturesComptabiliseesAgent(data.reverse());
      } else {
        console.log("Une erreur est survenue !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFuturesLivraisons = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/achat/livraison`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setFuturesLivraisons(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainContext.Provider
      value={{
        isLoadingProducts,
        isLoadingClients,
        isLoadingClient,
        isLoadingCA,
        isLoadingBonsCommande,
        isLoadingReliquat,
        setIsLoadingProducts,
        setIsLoadingClients,
        isLoadingVolumeClient,
        isLoadingBestSeller,
        productById,
        getProductById,
        allProducts,
        setAllProducts,
        getProducts,
        clients,
        setClients,
        getClientById,
        clientById,
        getVolumeByClient,
        totalVolumeByClient,
        getClientsByRepresentantId,
        getStockProductById,
        stockProduct,
        getInfosAllRepresentants,
        representants,
        usRepresentants,
        viewportWidth,
        viewportHeight,
        getFacturesByClient,
        factures,
        getBonsCommandeByClient,
        bonsCommande,
        getArticlesByDoc,
        getArticlesByFacture,
        current,
        setCurrent,
        getAllArticlesByDocTypeByClient,
        allArticlesByClientByBonDeCommande,
        allArticlesByClientByFacture,
        getCAByRepresentant,
        caByRepresentant,
        getVolumeByRepresentant,
        volumeByRepresentant,
        getNewClientsByRepresentant,
        newClientsByRepresentant,
        getTopAndFlopClientsByRepresentant,
        topAndFlopClientsByRepresentant,
        getTotalReliquatByRepresentant,
        totalReliquatByRepresentant,
        getTotalFacturesImpayesByAgent,
        totalFacturesImpayesByRepresentant,
        getDocumentsByRepresentant,
        bonsDeCommandeAgent,
        preparationsDeLivraisonAgent,
        bonsDeLivraisonAgent,
        facturesAgent,
        facturesComptabiliseesAgent,
        getTotalFacturesImpayesByClientN,
        getTotalFacturesImpayesByClientNMoinsUn,
        totalFacturesImpayesByClientN,
        totalFacturesImpayesByClientNMoinsUn,
        getTotalDuByClient,
        totalDuClient,
        futuresLivraisons,
      }}>
      {children}
    </MainContext.Provider>
  );
};

export { Provider };
export default MainContext;
