import { Select } from "antd";
import i18n from "../../i18n";

const LanguageSwitcher = () => {
  const lngs = [
    { label: "Français", value: "fr" },
    { label: "English", value: "en" },
  ];

  const onLanguageChange = (value) => {
    // console.log(value);
    i18n.changeLanguage(value);
  };

  return (
    <div>
      <Select
        style={{
          width: 120,
          color: "#fff",
        }}
        bordered={false}
        options={lngs}
        onChange={onLanguageChange}
        defaultValue={i18n.resolvedLanguage}
      ></Select>
    </div>
  );
};

export default LanguageSwitcher;
