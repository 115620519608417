import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const DatePickerCustom = ({ onDeliveryDateChange }) => {
  const [formatedDefaultTodayDate, setFormatedDefaultTodayDate] = useState();
  const { t } = useTranslation();
  const dateFormat = "DD/MM/YYYY";
  const defaultDate = new Date();

  // useEffect(() => {
  //   formatDateFrenchFormat(defaultDate);
  //   setFormatedDefaultTodayDate(formatDateFrenchFormat(defaultDate));
  // }, []);

  // const formatDateFrenchFormat = (dateInstance) => {
  //   console.log(dateInstance);
  //   let day = dateInstance.getDate();
  //   let month = dateInstance.getMonth() + 1;
  //   let year = dateInstance.getFullYear();

  //   return `${day.toString().padStart(2, "0")}/${month
  //     .toString()
  //     .padStart(2, "0")}/${year}`;
  // };

  const onChange = (date, dateString) => {
    onDeliveryDateChange(date, dateString);
    // console.log(date?._d, dateString);
  };

  return (
    <>
      <h3>{t("Date de livraison")}</h3>
      <DatePicker
        style={{ width: "100%" }}
        onChange={onChange}
        format={dateFormat}
        // defaultValue={formatedDefaultTodayDate}
      />
    </>
  );
};

export default DatePickerCustom;
