import { Col, Empty, Row } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Loader, Produit, SearchBar } from "../../components";
import MainContext from "../../contexts/MainContext";
import AuthContext from "../../contexts/AuthContext";
import PriceContext from "../../contexts/PriceContext";
import SearchContext from "../../contexts/SearchContext";

const ProduitsPageByFamille = () => {
  const { allProducts, futuresLivraisons } = useContext(MainContext);
  const { user } = useContext(AuthContext);
  const { idDevise } = useContext(PriceContext);
  const { filters, saveFilters, searchTextProducts, setSearchTextProducts } =
    useContext(SearchContext);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const [selectedBrandTag, setSelectedBrandTag] = useState("");
  const [categoryTag, setCategoryTag] = useState("");
  const [selectedCategoryTag, setSelectedCategoryTag] = useState("");
  const [materialTag, setMaterialTag] = useState("");
  const [selectedMaterialTag, setSelectedMaterialTag] = useState("");

  const [isAvailableTagSelected, setIsAvailableTagSelected] = useState(false);
  const [isNewTagSelected, setIsNewTagSelected] = useState(false);

  const tableId = "searchProducts";

  useEffect(() => {
    if (allProducts.length > 0) {
      setIsLoadingProducts(false);
    }
  }, [allProducts]);

  const handleBrandChange = (tag, checked) => {
    const newBrandTag = checked ? tag : "";
    setSelectedBrandTag(newBrandTag);
    setCategoryTag(checked ? tag.enfants : "");
    handleSaveTagsFilters("brandTag", checked ? tag : undefined);

    if (!checked) {
      // Réinitialiser les tags de catégorie et de matériau
      setSelectedCategoryTag("");
      setMaterialTag("");
      setSelectedMaterialTag("");
      handleSaveTagsFilters("categoryTag", undefined);
      handleSaveTagsFilters("materialTag", undefined);
    }
  };

  const handleCategoryChange = (tag, checked) => {
    const newCategoryTag = checked ? tag : "";
    setSelectedCategoryTag(newCategoryTag);
    setMaterialTag(checked ? tag.enfants : "");
    handleSaveTagsFilters("categoryTag", checked ? tag : undefined);

    if (!checked) {
      // Réinitialiser le tag de matériau
      setSelectedMaterialTag("");
      handleSaveTagsFilters("materialTag", undefined);
    }
  };

  const handleMaterialChange = (tag, checked) => {
    const newMaterialTag = checked ? tag : "";
    setSelectedMaterialTag(newMaterialTag);
    handleSaveTagsFilters("materialTag", checked ? tag : undefined);
  };

  const handleAvailableChange = (checked) => {
    setIsAvailableTagSelected(checked);
    handleSaveTagsFilters("availableTag", checked ? true : undefined);
  };

  const handleNewChange = (checked) => {
    setIsNewTagSelected(checked);
    handleSaveTagsFilters("newTag", checked ? true : undefined);
  };

  const handleResetFilters = () => {
    setSelectedBrandTag("");
    setCategoryTag("");
    setSelectedCategoryTag("");
    setMaterialTag("");
    setSelectedMaterialTag("");
    setIsAvailableTagSelected(false);
    setIsNewTagSelected(false);
    saveFilters(tableId, {
      brandTag: undefined,
      categoryTag: undefined,
      materialTag: undefined,
      availableTag: undefined,
    });
  };

  const handleSaveTagsFilters = (filterName, value) => {
    saveFilters(tableId, {
      [filterName]: value,
    });
  };

  useEffect(() => {
    const tableFilters = filters[tableId] || {};

    if (tableFilters.searchText) {
      setSearchTextProducts(tableFilters.searchText);
    } else {
      setSearchTextProducts("");
    }

    if (tableFilters.brandTag) {
      setSelectedBrandTag(tableFilters.brandTag);
      setCategoryTag(tableFilters.brandTag.enfants);
    } else {
      setSelectedBrandTag("");
      setCategoryTag("");
    }

    if (tableFilters.categoryTag) {
      setSelectedCategoryTag(tableFilters.categoryTag);
      setMaterialTag(tableFilters.categoryTag.enfants);
    } else {
      setSelectedCategoryTag("");
      setMaterialTag("");
    }

    if (tableFilters.materialTag) {
      setSelectedMaterialTag(tableFilters.materialTag);
    } else {
      setSelectedMaterialTag("");
    }

    if (tableFilters.availableTag) {
      setIsAvailableTagSelected(tableFilters.availableTag);
    } else {
      setIsAvailableTagSelected(false);
    }

    if (tableFilters.newTag) {
      setIsNewTagSelected(tableFilters.newTag);
    } else {
      setIsNewTagSelected(false);
    }
  }, [filters, tableId, allProducts]);

  const filteredProducts = useMemo(() => {
    return allProducts.filter((product) => {
      let matches = true;

      if (searchTextProducts) {
        matches =
          matches && product.Intitule.toLowerCase().includes(searchTextProducts.toLowerCase());
      }

      if (selectedBrandTag !== "") {
        matches = matches && product.IdCatalogue1 === selectedBrandTag.id;
      }

      if (selectedCategoryTag !== "") {
        matches = matches && product.IdCatalogue2 === selectedCategoryTag.id;
      }

      if (selectedMaterialTag !== "") {
        matches = matches && product.IdCatalogue3 === selectedMaterialTag.id;
      }

      if (isAvailableTagSelected) {
        matches = matches && product.QteStock - product.QteReserve > 5;
      }

      if (isNewTagSelected) {
        matches = matches && product.Statistique2 === "NOUVEAUTE";
      }

      return matches;
    });
  }, [
    allProducts,
    searchTextProducts,
    selectedBrandTag,
    selectedCategoryTag,
    selectedMaterialTag,
    isAvailableTagSelected,
    isNewTagSelected,
  ]);

  const handleOnSearch = (value) => {
    saveFilters(tableId, {
      searchText: value,
    });
  };

  useEffect(() => {
    console.log(filteredProducts);
  }, [filteredProducts]);

  return isLoadingProducts ? (
    <Loader />
  ) : (
    <div>
      <SearchBar
        onSearch={handleOnSearch}
        selectedBrandTag={selectedBrandTag}
        categoryTag={categoryTag}
        selectedCategoryTag={selectedCategoryTag}
        materialTag={materialTag}
        selectedMaterialTag={selectedMaterialTag}
        handleBrandChange={handleBrandChange}
        handleCategoryChange={handleCategoryChange}
        handleMaterialChange={handleMaterialChange}
        isAvailableTagSelected={isAvailableTagSelected}
        handleAvailableChange={handleAvailableChange}
        isNewTagSelected={isNewTagSelected}
        handleNewChange={handleNewChange}
        handleResetFilters={handleResetFilters}
      />
      {filteredProducts.length > 0 ? (
        <Row gutter={[16, 16]}>
          {filteredProducts.map((produit) => {
            const futureLivraison = futuresLivraisons.find(
              (livraison) => livraison.RefArticle === produit.RefArticle
            );
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                key={produit.Reference}
                style={{ display: "flex" }}>
                <Link
                  to={`/produits/${produit.Reference}`}
                  key={produit.Reference}
                  state={{ ...produit }}
                  style={{ display: "flex", flex: "1 1 auto" }}>
                  <Produit
                    produit={{ ...produit }}
                    devise={idDevise}
                    user={user}
                    futureLivraison={futureLivraison || null}
                  />
                </Link>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description={<span>Aucun produit ne correspond à votre recherche</span>} />
      )}
    </div>
  );
};

export default ProduitsPageByFamille;
