import { useNavigate, useRouteError } from "react-router-dom";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const { t } = useTranslation();
  console.error(error);

  return (
    <div
      id="error-page"
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>Oops!</h1>
        <p>{t("Sorry, an unexpected error has occurred.")}</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <Button onClick={() => navigate("/")}>{t("Back to home")}</Button>
      </div>
    </div>
  );
};

export default ErrorPage;
