import { useContext } from "react";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { Card, Col, Row } from "antd";
import { EuroTwoTone } from "@ant-design/icons";
import "./dashboard-agent.css";
import { useTranslation } from "react-i18next";

const CAByYear = () => {
  const { caByRepresentant, viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  // useEffect(() => {
  //   console.log("CA: ", caByRepresentant);
  // }, [caByRepresentant]);

  return (
    <>
      {/* CA CURRENT YEAR CARD */}
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Card
          title={
            viewportWidth < 1181 ? (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CA EN COURS")}
                </p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <EuroTwoTone twoToneColor="#D6A2E8" />
                </span>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CHIFFRE D'AFFAIRE ANNÉE EN COURS")}
                </p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <EuroTwoTone twoToneColor="#D6A2E8" />
                </span>
              </div>
            )
          }
          style={{ height: "100%" }}
          bodyStyle={
            viewportWidth < 1181
              ? {
                  padding: "16px 16px",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }
              : {
                  padding: "16px 48px",
                  textAlign: "center",
                  fontSize: "1.6rem",
                }
          }>
          {caByRepresentant?.currentYear?.CAHT && caByRepresentant?.currentYear?.CAHT > 0 ? (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  CA HT :
                </p>{" "}
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  TVA :
                </p>{" "}
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  Frais de port :
                </p>{" "}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  {" "}
                  {caByRepresentant?.currentYear?.CAHT?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    margin: 0,
                    fontSize: "0.8rem",
                  }}>
                  {" "}
                  {caByRepresentant?.currentYear?.TVA?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    margin: 0,
                    fontSize: "0.8rem",
                  }}>
                  {" "}
                  {caByRepresentant?.currentYear?.fraisDePort?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
              </Col>
            </Row>
          ) : caByRepresentant?.currentYear?.CAHT === 0 ? (
            <p>0€</p>
          ) : (
            <Loader />
          )}
        </Card>
      </Col>
      {/* CA LAST YEAR CARD */}
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Card
          title={
            viewportWidth < 1181 ? (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CA N-1")}
                </p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <EuroTwoTone twoToneColor="#D6A2E8" />
                </span>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CHIFFRE D'AFFAIRE ANNEE N-1")}
                </p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <EuroTwoTone twoToneColor="#D6A2E8" />
                </span>
              </div>
            )
          }
          style={{ height: "100%" }}
          bodyStyle={
            viewportWidth < 1181
              ? {
                  padding: "16px 16px",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }
              : {
                  padding: "16px 48px",
                  textAlign: "center",
                  fontSize: "1.6rem",
                }
          }>
          {caByRepresentant?.lastYear?.CAHT && caByRepresentant?.lastYear?.CAHT > 0 ? (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  CA HT :
                </p>{" "}
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  TVA :
                </p>{" "}
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  Frais de port :
                </p>{" "}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  {" "}
                  {caByRepresentant?.lastYear?.CAHT?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    margin: 0,
                    fontSize: "0.8rem",
                  }}>
                  {" "}
                  {caByRepresentant?.lastYear?.TVA?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    margin: 0,
                    fontSize: "0.8rem",
                  }}>
                  {" "}
                  {caByRepresentant?.lastYear?.fraisDePort?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
              </Col>
            </Row>
          ) : caByRepresentant?.lastYear?.CAHT === 0 ? (
            <p>0€</p>
          ) : (
            <Loader />
          )}
        </Card>
      </Col>
    </>
  );
};

export default CAByYear;
