import { Card } from "antd";
import { useContext, useEffect, useState } from "react";
import { UpdateClient } from "../boutons";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";
import PriceContext from "../../contexts/PriceContext";

const DashboardClientInfosClient = ({ clientById, dateDerniereCommande }) => {
  const [dateCreationClient, setDateCreationClient] = useState();
  const { activeRole } = useContext(PriceContext);
  const { t } = useTranslation();

  useEffect(() => {
    getDateCreationClient(clientById);
  }, [clientById]);

  const getDateCreationClient = (client) => {
    if (client && client.DateCreation) {
      let timestamp = parseInt(
        client.DateCreation.split("(")[1].split(")")[0].split("+")[0]
      );
      let date = new Date(timestamp);
      setDateCreationClient(date);
    }
  };

  return clientById ? (
    <Card
      title={clientById.Intitule}
      style={{ height: "100%" }}
      bodyStyle={{
        height: "90%",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>{t("Id client")}: </span>
            {clientById.NumeroTiers ? clientById.NumeroTiers : "N/A"}
          </p>
          <p>{clientById.Adresse ? clientById.Adresse : "N/A"}</p>
          <p>
            {clientById.CodePostal ? clientById.CodePostal : "N/A"}{" "}
            {clientById.Ville ? clientById.Ville : "N/A"}
          </p>
          <p>{clientById.Region ? clientById.Region : "N/A"}</p>
          <p>{clientById.Pays ? clientById.Pays : "N/A"}</p>
        </div>
        <div>
          {activeRole === "repUs" ? (
            <p>
              <span style={{ fontWeight: "bold" }}>Billing terms: </span>{" "}
              {clientById.Statistique03}
            </p>
          ) : (
            <></>
          )}
          <p>
            <span style={{ fontWeight: "bold" }}>Contact: </span>
            {clientById.Contact ? clientById.Contact : "N/A"}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Email: </span>
            {clientById.Email ? clientById.Email : "N/A"}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>{t("Téléphone")}: </span>
            {clientById.Telephone ? clientById.Telephone : "N/A"}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>{t("Client depuis")}: </span>
            {dateCreationClient ? dateCreationClient.getFullYear() : ""}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {t("Dernière commande")}:{" "}
            </span>
            {dateDerniereCommande?.getFullYear() > 2015
              ? `${dateDerniereCommande.getDate()}/${
                  dateDerniereCommande.getMonth() + 1
                }/${dateDerniereCommande.getFullYear()}`
              : "N/A"}
          </p>
        </div>
        <UpdateClient clientId={clientById.NumeroTiers} />
      </div>
    </Card>
  ) : (
    <Loader />
  );
};

export default DashboardClientInfosClient;
