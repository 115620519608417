import { Button, Input, Tag } from "antd";
import "./search-bar.css";
import SearchContext from "../../contexts/SearchContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const catalogues = [
  {
    id: 2,
    nom: "NATHALIE BLANC PARIS",
    enfants: [
      {
        id: 12,
        nom: "OPTIQUES",
        enfants: [
          { id: 14, nom: "METAL" },
          { id: 15, nom: "ACETATE" },
          { id: 16, nom: "COMBINE" },
        ],
      },
      {
        id: 13,
        nom: "SOLAIRES",
        enfants: [
          { id: 17, nom: "METAL" },
          { id: 18, nom: "ACETATE" },
          { id: 19, nom: "COMBINE" },
        ],
      },
    ],
  },
  {
    id: 38,
    nom: "MONSIEUR BLANC",
    enfants: [
      {
        id: 39,
        nom: "OPTIQUES",
        enfants: [
          { id: 41, nom: "ACETATE" },
          { id: 42, nom: "COMBINE" },
          { id: 43, nom: "METAL" },
          { id: 50, nom: "3D" },
        ],
      },
      {
        id: 40,
        nom: "SOLAIRES",
        enfants: [
          { id: 44, nom: "ACETATE" },
          { id: 45, nom: "COMBINE" },
          { id: 46, nom: "METAL" },
          { id: 51, nom: "3D" },
        ],
      },
    ],
  },
  {
    id: 1,
    nom: "BLANC",
    enfants: [
      {
        id: 4,
        nom: "OPTIQUES",
        enfants: [
          { id: 6, nom: "METAL" },
          { id: 7, nom: "ACETATE" },
          { id: 8, nom: "COMBINE" },
        ],
      },
      {
        id: 5,
        nom: "SOLAIRES",
        enfants: [
          { id: 9, nom: "METAL" },
          { id: 10, nom: "ACETATE" },
          { id: 11, nom: "COMBINE" },
        ],
      },
    ],
  },
  {
    id: 29,
    nom: "BAUMER",
    enfants: [
      {
        id: 34,
        nom: "OPTIQUES",
      },
      {
        id: 35,
        nom: "SOLAIRES",
      },
    ],
  },
  {
    id: 52,
    nom: "SHELTER",
    enfants: [
      {
        id: 53,
        nom: "OPTIQUES",
      },
      {
        id: 54,
        nom: "SOLAIRES",
      },
    ],
  },
  { id: 107, nom: "OUCHENIR" },
  { id: 61, nom: "ACCESSOIRES" },
  { id: 65, nom: "PLV NB" },
  { id: 105, nom: "PLV MB" },
  { id: 106, nom: "PLV BLANC" },
  { id: 112, nom: "AF" },
];

const SearchBar = ({
  onSearch,
  selectedBrandTag,
  categoryTag,
  selectedCategoryTag,
  materialTag,
  selectedMaterialTag,
  handleBrandChange,
  handleCategoryChange,
  handleMaterialChange,
  isAvailableTagSelected,
  handleAvailableChange,
  isNewTagSelected,
  handleNewChange,
  handleResetFilters,
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const { searchTextProducts } = useContext(SearchContext);
  const { t } = useTranslation();

  const renderFilters = () => {
    if (selectedBrandTag !== "" && categoryTag?.length > 0 && materialTag?.length > 0) {
      return (
        <div className="searchBar-filters__category-container">
          <Button className="reset-btn" ghost danger size="small" onClick={handleResetFilters}>
            Reset
          </Button>
          <Tag.CheckableTag className="tag" checked={true}>
            {selectedBrandTag.nom}
          </Tag.CheckableTag>
          <span className="tag-separator">&gt;</span>
          <Tag.CheckableTag
            className="tag"
            checked={true}
            onChange={(checked) => handleCategoryChange(selectedCategoryTag, checked)}>
            {selectedCategoryTag.nom}
          </Tag.CheckableTag>
          <span className="tag-separator">&gt;</span>
          {materialTag.map((tag) => (
            <Tag.CheckableTag
              className={`material-tag tag ${
                selectedMaterialTag === tag ? "active-tag" : "inactive-tag"
              }`}
              key={tag.id}
              checked={selectedMaterialTag === tag}
              onChange={(checked) => handleMaterialChange(tag, checked)}>
              {tag.nom}
            </Tag.CheckableTag>
          ))}
        </div>
      );
    }
    if (selectedBrandTag !== "" && categoryTag?.length > 0) {
      return (
        <div className="searchBar-filters__category-container">
          <Button className="reset-btn" ghost danger size="small" onClick={handleResetFilters}>
            Reset
          </Button>
          <Tag.CheckableTag
            className="tag"
            checked={true}
            onChange={(checked) => handleBrandChange(selectedBrandTag, checked)}>
            {selectedBrandTag.nom}
          </Tag.CheckableTag>
          <span className="tag-separator">&gt;</span>
          {categoryTag.map((tag) => (
            <Tag.CheckableTag
              className={`category-tag tag ${
                selectedCategoryTag === tag ? "active-tag" : "inactive-tag"
              }`}
              key={tag.id}
              checked={selectedCategoryTag === tag}
              onChange={(checked) => handleCategoryChange(tag, checked)}>
              {tag.nom}
            </Tag.CheckableTag>
          ))}
        </div>
      );
    }

    return (
      <div className="searchBar-filters__category-container">
        <Button className="reset-btn" ghost danger size="small" onClick={handleResetFilters}>
          Reset
        </Button>
        {catalogues.map((tag) => (
          <Tag.CheckableTag
            className={`brand-tag tag ${selectedBrandTag === tag ? "active-tag" : "inactive-tag"}`}
            key={tag.id}
            checked={selectedBrandTag === tag}
            onChange={(checked) => handleBrandChange(tag, checked)}>
            {tag.nom}
          </Tag.CheckableTag>
        ))}
      </div>
    );
  };

  return (
    <div className="searchBar__container">
      <div
        className="searchBar-filters__container"
        style={filtersVisible ? { width: "100%" } : { width: "150px" }}>
        <div
          className={`searchBar-filters__header ${filtersVisible ? "displayUI" : "hiddeUI"}`}
          onClick={() => setFiltersVisible((previousState) => !previousState)}>
          <span>{t("Filters")}</span>
          {filtersVisible ? <span className="close-filters-cross">X</span> : ""}
        </div>
        <div
          className={`sarchBar-filters__category-wrapper ${filtersVisible ? "visible" : "hidden"}`}>
          {renderFilters()}
          <div className="searchBar-filters__category-container">
            <Tag.CheckableTag
              className={`tag ${isAvailableTagSelected ? "active-tag" : "inactive-tag"}`}
              checked={isAvailableTagSelected}
              onChange={handleAvailableChange}>
              {t("DISPONIBLES")}
            </Tag.CheckableTag>
            <Tag.CheckableTag
              className={`tag ${isNewTagSelected ? "active-tag" : "inactive-tag"}`}
              checked={isNewTagSelected}
              onChange={handleNewChange}>
              {t("NOUVEAUTES")}
            </Tag.CheckableTag>
          </div>
        </div>
      </div>
      <div className="searchBar-searchBar__container">
        <Input.Search
          placeholder="Votre recherche"
          defaultValue={searchTextProducts || null}
          onSearch={onSearch}
          size="large"
          enterButton
          allowClear
        />
      </div>
    </div>
  );
};

export default SearchBar;
