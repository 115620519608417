import { Row, Col, InputNumber, Slider } from "antd";
import "./quantity-selector.css";

const QuantitySelector = ({
	inputValue,
	setInputValue,
	stockProduct,
	productById,
}) => {
	const onChange = (newValue) => {
		setInputValue(newValue);
	};

	return (
		<Row className="quantity-selector-container">
			<Col span={16}>
				<Slider
					min={1}
					max={productById.Publie ? 30 : stockProduct}
					onChange={onChange}
					value={typeof inputValue === "number" ? inputValue : 0}
					disabled={
						!productById.Publie ? (stockProduct > 0 ? false : true) : false
					}
					tooltip={{
						formatter: null,
					}}
				/>
			</Col>
			<Col span={8}>
				<InputNumber
					size="large"
					controls={false}
					min={0}
					max={productById.Publie ? 30 : stockProduct}
					className="input-number-quantity-selector"
					value={inputValue}
					onChange={onChange}
					disabled={
						!productById.Publie ? (stockProduct > 0 ? false : true) : false
					}
				/>
			</Col>
		</Row>
	);
};

export default QuantitySelector;
