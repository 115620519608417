import { Select } from "antd";

const SelectClientOnCart = ({
  clients,
  onClientChange,
  setClient,
  waitingOrderClient,
}) => {
  const pushClientsInOptions = () => {
    const options = [];
    if (clients.length) {
      clients?.forEach((client) => {
        options.push({ label: client.Intitule, value: client.NumeroTiers });
      });
    }
    return options;
  };

  const handleClChange = (value) => {
    onClientChange(value);
    const clientTmp = clients.filter((cl) => cl.NumeroTiers === value);
    setClient(clientTmp);
  };

  return (
    <>
      <h3>Client</h3>
      <Select
        value={waitingOrderClient}
        allowClear
        showSearch
        style={{
          width: "100%",
        }}
        placeholder="Choix du client"
        onChange={handleClChange}
        options={pushClientsInOptions()}
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
      ></Select>
    </>
  );
};

export default SelectClientOnCart;
