import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const updateClientStyle = {
	backgroundColor: "#6ab04c",
	width: "100%",
	padding: "8px",
	border: "none",
	color: "white",
	fontSize: "1rem",
	cursor: "pointer",
};

const UpdateClient = ({ clientId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const clickUpdateClientBtn = () => {
		navigate(`/client/update/${clientId}`);
	};

	return (
		<button style={updateClientStyle} onClick={() => clickUpdateClientBtn()}>
			{t("Modifier le client")}
		</button>
	);
};

export default UpdateClient;
