import { Button, Card, Col, InputNumber, Progress } from "antd";
import "./dashboard-agent.css";
import { useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { useTranslation } from "react-i18next";

const ObjectifCA = () => {
  const [displayInput, setDisplayInput] = useState("none");
  const [displayProgress, setDisplayProgress] = useState("flex");
  const [objective, setObjective] = useState(localStorage.getItem("objectiveCA"));
  const [percent, setPercent] = useState(0);
  const { caByRepresentant } = useContext(MainContext);
  const { t } = useTranslation();

  useEffect(() => {
    calculPercentage(caByRepresentant?.currentYear?.CAHT?.toFixed(2), objective);
  }, [caByRepresentant, objective]);

  const onInputChange = (value) => {
    // console.log(value);
    setObjective(value);
  };

  const onNewObjectiveClick = () => {
    if (displayInput === "none") {
      setDisplayInput("flex");
      setDisplayProgress("none");
    } else {
      setDisplayInput("none");
      setDisplayProgress("flex");
    }
  };

  const onInputOKClick = () => {
    setDisplayInput("none");
    setDisplayProgress("flex");
    // console.log(objective);
    localStorage.setItem("objectiveCA", objective);
  };

  const calculPercentage = (caEnCours, objective) => {
    let percentageTmp = ((objective - caEnCours) / objective) * 100;
    let percentage = 100 - percentageTmp;
    // console.log(percentage);
    setPercent(percentage.toFixed(1));
  };

  return (
    <>
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Card
          style={{ height: "100%" }}
          bodyStyle={{
            padding: "0 8px 8px 8px",
            textAlign: "center",
            fontSize: "2rem",
            height: "100%",
          }}>
          <Button size="small" onClick={onNewObjectiveClick}>
            {t("Nouvel Objectif")}
          </Button>

          <div style={{ display: displayInput }}>
            <InputNumber
              size="small"
              placeholder={t("Entrez un objectif")}
              onPressEnter={onInputOKClick}
              onChange={onInputChange}
              min={1}
              max={99999999}
              style={{ width: "100%" }}
            />
            <Button onClick={onInputOKClick}>OK</Button>
          </div>
          <div style={{ display: displayProgress, justifyContent: "center" }}>
            <Progress type="circle" percent={percent} strokeColor="#D6A2E8" strokeWidth={4} />
          </div>
          <p style={{ fontSize: "0.8rem", fontWeight: "300", margin: 0 }}>
            {t("Objectif actuel")}: <span style={{ fontWeight: "600" }}>{objective ?? "N/A"}€</span>
          </p>
        </Card>
      </Col>
    </>
  );
};

export default ObjectifCA;
