import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Button, Card, Col, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import "./downloads.css";
import { useTranslation } from "react-i18next";

const DownloadsPage = () => {
  const { accessTokenJwt } = useContext(AuthContext);
  const { t } = useTranslation();

  const downloadSepa = async (lang) => {
    // console.log("clicked");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/download-sepa-${lang}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenJwt}`,
        },
      }
    );

    if (response.ok) {
      let blob = await response.blob();
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.download = `sepa-${lang}.pdf`;
      document.body.appendChild(a); // Cette ligne est nécessaire pour Firefox
      a.click();
      window.URL.revokeObjectURL(url); // Libérer l'URL du blob
      document.body.removeChild(a); // Supprimer l'élément du DOM
    } else {
      // Gérer les erreurs ici
      console.error("Failed to download file");
    }
  };

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Card
          title={t("SEPA FRANÇAIS")}
          className="downloads-page__item-container"
        >
          <Button onClick={() => downloadSepa("fr")} block size="large">
            {t("Download")} <DownloadOutlined />
          </Button>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Card
          title={t("SEPA ANGLAIS")}
          className="downloads-page__item-container"
        >
          <Button onClick={() => downloadSepa("en")} block size="large">
            {t("Download")} <DownloadOutlined />
          </Button>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Card
          title={t("SEPA ALLEMAND")}
          className="downloads-page__item-container"
        >
          <Button onClick={() => downloadSepa("de")} block size="large">
            {t("Download")} <DownloadOutlined />
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default DownloadsPage;
